/**
 * @fileoverview Defines a function that returns a cluster's information.
 */
import axios from 'axios';

const _clusterDataCache = {};
const _clusterDataUrl = {
  'viridia': 'data/viridia.json',
  'montana': 'data/montana.json',
  'margonite': 'data/margonite.json',
  'prismatiq': 'data/prismatiq.json',
  'citrine': 'data/citrine.json',
  'scarlet': 'data/scarlet.json',
};


/**
 * Get the cluster information.
 */
export function getClusterData(clusterId) {
  return new Promise((resolve, reject) => {
    if (!_clusterDataUrl.hasOwnProperty(clusterId)) {
      reject(new Error(`No position data for the cluster '${clusterId}'`));
      return;
    }

    if (_clusterDataCache.hasOwnProperty(clusterId)) {
      resolve(_clusterDataCache[clusterId]);
      return;
    }

    const url = _clusterDataUrl[clusterId];
    axios
      .get(url)
      .then(response => {
        _clusterDataCache[clusterId] = response.data;
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}
