/**
 * @fileoverview A screen to convert bookings to full booking.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';

import * as PricechartService from '@Services/PricechartService';
import * as UnitActions from '@Stores/units/actions';
import * as BookingsActions from '@Stores/bookings/actions';
import * as MetaActions from '@Stores/meta/actions';
import * as Utils from '@Lib/index';

import DialogFooter from '@Components/DialogFooter';
import Button from '@Components/Button';
import SVGIcon from '@Components/SVGIcon';
import ScreenLoadingIndicator from '@Components/ScreenLoadingIndicator';
import { Container, Collapse } from 'reactstrap';
import BalanceBookingFeeForm from './BalanceBookingFeeForm';
import { format } from 'date-fns';

/**
 * Map redux store to props.
 */
const mapStateToProps = (state, ownProps) => {
  const booking = state.bookings.bookings[ownProps.bookingId];

  const paymentPlans = Boolean(booking && booking.info)
    ? state.units.paymentPlans.find(p => p.unitno === booking.unitNo)
    : null;

  const paymentPlan = Boolean(paymentPlans && booking)
    ? paymentPlans.plans.find(p => p.code2 === booking.info.paymentMethod)
    : null;

  const isLoading = state.meta.loading['payment_plans'] === true
    || state.meta.loading['bookings'] === true;

  const isSubmitting = state.meta.loading['convert_booking'] === true;

  return { booking, paymentPlan, isSubmitting, isLoading };
};


/**
 * Validation Schema
 */
const validationSchema = yup.object().shape({
  method: yup
    .string()
    .oneOf(['Cash', 'Credit Card', 'Bank Transfer', 'Debit Card']),
  receipt: yup
    .string()
    .required('This field is required.')
});


/**
 * `<ConvertBookingScreen/> is a screen to convert a booking to full booking.
 */
class ConvertBookingScreen extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    bookingId: PropTypes.string.isRequired,
    booking: PropTypes.object.isRequired,
  }

  /**
   * Initial State
   */
  state = {
    accordion: {
      details: false,
      paymentPlan: false,
      bookingFee: true,
    },
  }

  componentDidMount() {
    this.resolveBooking();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.resolveBooking();
      this.resetForm();
    }
  }

  resolveBooking() {
    if (!this.props.booking.info) {
      this.props
        .dispatch(BookingsActions.getBooking(this.props.booking.bookingId))
        .catch(err => Utils.throwComponentDidCatch(this, new Error(err)));
    }

    if (!this.props.paymentPlan) {
      this.props
        .dispatch(UnitActions.getPaymentPlan(this.props.booking.unitNo))
        .catch(err => Utils.throwComponentDidCatch(this, new Error(err)));
    }
  }

  resetForm() {
    this.props.setFieldValue('method', 'Credit Card');
    this.props.setFieldValue('receipt', '');
    this.setState({
      accordion: {
        details: false,
        paymentPlan: false,
        bookingFee: true
      }
    });
  }

  toggleAccordion(key) {
    this.setState((prevState) => ({
      accordion: {
        ...prevState.accordion,
        [key]: !prevState.accordion[key],
      }
    }));
  }

  handleBack = (e) => {
    this.props.history.push('/manage-booking');
  }

  handleSubmit = (e) => {
    this.props.submitForm();
    validationSchema.isValid(this.props.values).then(isValid => {
      if (!isValid && this.props.booking.info.bookingFeeBalance > 0) {
        Utils.triggerCssClass('#convert-booking-submit', ['btn-danger', 'shake'], 820);
      }
    });
  }

  /**
   * Render Function.
   */
  render() {
    const { booking, paymentPlan } = this.props;
    return (
      <div className="convert-booking-screen">
        <ScreenLoadingIndicator show={this.props.isLoading} fill={false}/>

        {booking.info &&
          <div>
            {/* Masthead */}
            <div className="convert-booking-masthead">
              <span className="convert-booking-masthead__name">
                {booking.info.fullName} {booking.status === 'sold' && <span className="badge badge-danger">SOLD</span>}
              </span>
              <span className="convert-booking-masthead__details">
                {booking.unitNo}
                &ensp;•&nbsp;
                {booking.cluster}
                &ensp;•&nbsp;
                Booked on {format(booking.createdAt, 'MMM D, YYYY')}
              </span>
            </div>
    
            {/* Buyer Details */}
            <div className="convert-booking-accordion" open={this.state.accordion.details ? true : undefined}>
              <button
                className="convert-booking-accordion__header"
                onClick={(e) => this.toggleAccordion('details')}
              >
                <h2>Buyer Details</h2>
                <span className="badge badge-success"><SVGIcon icon="check" small/></span>
                <div className="convert-booking-accordion__chevron ml-auto">
                  <SVGIcon icon="chevron" small/>
                </div>
              </button>
    
              <Collapse className="convert-booking-accordion__content" isOpen={this.state.accordion.details}>
                <ul className="details-card">
                  <li>
                    <h6 className="details-card__title">Name</h6>
                    <span>{booking.info.fullName}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">Mobile Number</h6>
                    <span>{booking.info.contactNo}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">Email Address</h6>
                    <span>{booking.info.email}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">Nationality</h6>
                    <span>{booking.info.country}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">State</h6>
                    <span>{booking.info.state || 'N/A'}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">City</h6>
                    <span>{booking.info.city}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">Address</h6>
                    <span>{[booking.info.address1, booking.info.address2, booking.info.address3].join('\n')}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">Postcode</h6>
                    <span>{booking.info.postcode}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">Tax Number</h6>
                    <span>{booking.info.taxNo}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">KTP/Passport Number</h6>
                    <span>{booking.info.identityNo}</span>
                  </li>
                  <li>
                    <h6 className="details-card__title">Identity Document</h6>
                    <div className="details-card__image">
                      <img src={booking.info.nricUrl}/>
                    </div>
                  </li>
                </ul>
              </Collapse>
            </div>
    
            {/* Payment Plan Details */}
            {paymentPlan &&
              <div className="convert-booking-accordion" open={this.state.accordion.paymentPlan ? true : undefined}>
                <button
                  className="convert-booking-accordion__header"
                  onClick={(e) => this.toggleAccordion('paymentPlan')}
                >
                  <h2>Payment Plan</h2>
                  <span className="badge badge-success"><SVGIcon icon="check" small/></span>
                  <div className="convert-booking-accordion__chevron ml-auto">
                    <SVGIcon icon="chevron" small/>
                  </div>
                </button>
    
                <Collapse className="convert-booking-accordion__content" isOpen={this.state.accordion.paymentPlan}>
                  <ul className="details-card">
                    <li>
                      <h6 className="details-card__title">Payment Plan</h6>
                      <span>{paymentPlan.name}</span>
                    </li>
                    <li>
                      <h6 className="details-card__title">Payment Period</h6>
                      <span>{paymentPlan.month} month(s)</span>
                    </li>
                    <li>
                      <h6 className="details-card__title">Total Price</h6>
                      <span>IDR {paymentPlan.price}</span>
                    </li>
                  </ul>
                </Collapse>
              </div>
            }
    
            {/* Booking Fee Details */}
            {booking.status !== 'sold' &&
              <div className="convert-booking-accordion" open={this.state.accordion.bookingFee ? true : undefined}>
                <button
                  className="convert-booking-accordion__header"
                  onClick={(e) => this.toggleAccordion('bookingFee')}
                >
                  <h2>Booking Fee</h2>
                  {this.props.booking.info.bookingFeeBalance <= 0 && <span className="badge badge-success"><SVGIcon icon="check" small/></span>}
                  <div className="convert-booking-accordion__chevron ml-auto">
                    <SVGIcon icon="chevron" small/>
                  </div>
                </button>
    
                <Collapse className="convert-booking-accordion__content" isOpen={this.state.accordion.bookingFee}>
                  <BalanceBookingFeeForm {...this.props} balancePayable={booking.info.bookingFeeBalance}/>
                </Collapse>
              </div>
            }
    
            <hr/>
            
            {/* Additional Actions */}
            {booking.status !== 'sold' &&
              <div className="convert-booking-actions">
                {/* Submit Button */}
                <Button id="convert-booking-submit" color="next" width="lg" disabled={this.props.isSubmitting} onClick={this.handleSubmit}>
                  <SVGIcon icon="check" small/>&ensp;Confirm Booking
                </Button>
              </div>
            }
          </div>
        }

      </div>
    )
  }

}

const FormikConvertBookingScreen = withFormik({

  /**
   * Map Initial props to values.
   */
  mapPropsToValues: (props) => {
    return {
      method: 'Credit Card',
      receipt: '',
    };
  },

  /**
   * Handle form submission.
   */
  handleSubmit: (values, { props, setSubmitting }) => {
    if (!confirm(`Confirm sale of ${props.booking.unitNo}?`)) {
      setSubmitting(false);
      return;
    }

    MetaActions.setLoading('convert_booking', true);
    PricechartService.uploadObjectUrl(values.receipt)
      .then(fileUrl => ({
        bookingId: props.booking.bookingId,
        unitId: props.booking.unitId,
        paymentReference: values.method,
        balancePaid: props.booking.info.bookingFeeBalance,
        proofOfPayment: fileUrl
      }))
      .then(data => PricechartService.convertToFullBook(data))
      .then(() => MetaActions.setLoading('convert_booking', false))
      .then(() => props.history.push(`/manage-booking/${props.booking.bookingId}/success`))
      .then(() => {
        MetaActions.setLoading('convert_booking', false);
        props.history.push(`/manage-booking/${props.booking.bookingId}/success`);

        const updatedBooking = props.booking;
        updatedBooking.status = 'sold';
        props.dispatch(BookingsActions.replaceBooking(updatedBooking));

        setSubmitting(false);
      })
      .catch(err => Utils.throwComponentDidCatch(this, new Error(err)));
  },

  /**
   * Specify the form validation schema using a Yup schema.
   * @reference https://github.com/jquense/yup
   */
  validationSchema: (props) => {
    if (props.booking.info) {
      return props.booking.info.bookingFeeBalance > 0
        ? validationSchema
        : yup.object(); // Return an empty validation schema if booking fee balance is 0
    } else {
      return validationSchema;
    }
  },

})(ConvertBookingScreen);

export default connect(mapStateToProps)(FormikConvertBookingScreen);
