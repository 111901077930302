/**
 * @fileoverview Defines a function to get a list of the user's prospects.
 */
import axios from 'axios';
import { ROOT_URL } from './constants';

/**
 * Define the API endpoint.
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostprospectsapi.php`;

/**
 * Get list of the current user's prospects from the API.
 */
export function getProspects() {
  return new Promise((resolve, reject) => {
    const request = axios.get(API_ENDPOINT);

    request.then(response => {
      if (response.data.error != '') {
        reject(new Error(response.data.error));
      }

      const prospects = _transform(response.data.prospects_list);
      resolve(prospects);
    });

    request.catch(error => reject(error));
  });
}


function _transform(prospects) {
  return prospects.map(prospect => ({
    id: prospect.id,
    dob: prospect.buyer_age,
    contactNo: prospect.buyer_contactno,
    email: prospect.buyer_email,
    firstName: prospect.buyer_firstname,
    lastName: prospect.buyer_lastname,
    maritalStatus: prospect.buyer_marital_status,
    occupation: prospect.buyer_occupation,
    purposeOfBuying: prospect.buyer_purpose_of_buying,
    salutation: prospect.buyer_salutation,
    createdAt: prospect.createdat,
    createdBy: prospect.createdby,
    acquisition: prospect.how_buyer_know_us,
  }));
}