import axios from 'axios';

import { ROOT_URL } from './constants';

/**
 * Define the API endpoint.
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostunitapi.php`;


/**
 * Get list of units from the API.
 * @param {string} clusterId
 * @returns Promise<Unit>
 */
export function getReserveUnits(clusterId) {
  return new Promise(resolve => {
    const params = {
      'filter_by_block': clusterId,
    };

    axios.get(API_ENDPOINT, { params: params }).then((response) => {
      if (response.data.error != '') {
        return Promise.reject(new Error(
          `Unable to resolve reservation units '${response.data.error}'`
        ));
      }
      resolve(response.data.reserve_units);
    });
  });
}
