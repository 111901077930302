/**
 * @fileoverview A component to display the payment plan picker's header.
 */
import React from 'react';

/**
 * <PaymentPlanHeader/> is a component to display the payment plan picker's
 * header.
 */
function PaymentPlanHeader(props) {
  return (
    <div className="payment-plan-picker-header">
      {/* Status */}
      <div className="payment-plan-picker-header__status">

      </div>

      {/* Terms */}
      <div className="payment-plan-picker-header__terms">
        Terms
      </div>

      {/* Period */}
      <div className="payment-plan-picker-header__period">
        Payment Period
      </div>

      {/* Price */}
      <div className="payment-plan-picker-header__price">
        Price
      </div>

      {/* Actions */}
      <div className="payment-plan-picker-header__actions">

      </div>
    </div>
  )
}

export default PaymentPlanHeader;
