/**
 * @fileoverview Defines action creators to interact with the `bookings` store.
 */

import * as PricechartService from '@Services/PricechartService';
import * as MetaActions from '@Stores/meta/actions';


/**
 * Action Types Enum
 */
export const ACTION_TYPES = {
  SET_BOOKING: 'SET_BOOKING',
  SET_BOOKINGS: 'SET_BOOKINGS',
  REPLACE_BOOKING: 'REPLACE_BOOKING',
}


/**
 * [Thunk] Create an action that gets a list of the current user's bookings.
 */
export function getBookings() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(MetaActions.setLoading("bookings", true));

      const request = PricechartService.getBookings();
      request.then(bookings => {
        dispatch(MetaActions.setLoading("bookings", false));
        dispatch(setBookings(bookings));
        resolve(bookings);
      });

      // Catch for errors
      request.catch(err => reject(err));
    });
  };
}


/**
 * [Thunk] Create an action that gets a booking's info.
 */
export function getBooking(bookingId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const booking = getState().bookings.bookings[bookingId];
      dispatch(MetaActions.setLoading('bookings', true));

      const request = PricechartService.getBookingInfo(bookingId);
      request.then(info => {
        booking.info = info;
        dispatch(replaceBooking(booking));
        dispatch(MetaActions.setLoading('bookings', false));
        resolve(booking);
      });

      // Catch for errors
      request.catch(err => reject(err));
    });
  }
}


export function replaceBooking(booking) {
  return {
    type: ACTION_TYPES.REPLACE_BOOKING,
    payload: booking
  }
}


export function setBookings(bookings) {
  return {
    type: ACTION_TYPES.SET_BOOKINGS,
    payload: bookings,
  }
}