/**
 * LavonII iPad Sales Kit Webviews
 * @author James Ooi, Jayson Yee
 * @organisation FOREFRONT International
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

window.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<App/>, document.getElementById('root'));
});
