/**
 * @fileoverview UnitMapButton is a button to select a unit.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Prop Types
 */
const propTypes = {
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  position: PropTypes.array,
  size: PropTypes.array,
  status: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}


/**
 * Default Props
 */
const defaultProps = {
  isActive: false,
  isSelected: false,
}


/**
 * <UnitMapButton/> is a button component to select a unit.
 */
function UnitMapButton(props) {
  if(props.position){
    return (
      <button
        className="unit-map-picker-btn"
        is-active={props.isActive ? '' : undefined}
        is-selected={props.isSelected ? '' : undefined}
        status={props.status}
        style={{
          left: props.position[0],
          top: props.position[1],
          width: props.size[0],
          height: props.size[1],
        }}
        onClick={props.onClick}
      />
    )
  }else{
    return null
  }
}

UnitMapButton.propTypes = propTypes;
UnitMapButton.defaultProps = defaultProps;

export default UnitMapButton;
