/**
 * @fileoverview A component to display and select a unit payment plan.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaymentPlanRow from './PaymentPlanRow';
import PaymentPlanHeader from './PaymentPlanHeader';


/**
 * Prop Types
 */
const propTypes = {
  /** The id of the selected payment plan. */
  value: PropTypes.string,

  /** The list of plans available */
  plans: PropTypes.array.isRequired,

  /** Called when an unit is selected. */
  onSelect: PropTypes.func.isRequired,
}


/**
 * <PaymentPlanPicker/> is a component to display and select a unit payment
 * plan.
 */
class PaymentPlanPicker extends Component {
  static propTypes = propTypes;

  handleSelect(e, paymentPlanId) {
    this.props.onSelect(paymentPlanId);
  }

  render() {
    return (
      <div className="payment-plan-picker">
        <PaymentPlanHeader/>

        {this.props.plans.map(plan =>
          <PaymentPlanRow
            key={plan.id}
            selected={plan.id === this.props.value}
            id={plan.id}
            terms={plan.name}
            period={`${plan.month} months`}
            price={plan.price}
            onSelect={this.handleSelect.bind(this)}
          />
        )}

      </div>
    )
  }
}

export default PaymentPlanPicker;
