/**
 * @fileoverview Defines a function to create a booking with the API.
 */
import * as yup from 'yup';
import axios from 'axios';
import format from 'date-fns/format';
import qs from 'query-string';

import { ROOT_URL } from './constants';


/**
 * API Endpoint
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostbookingapi.php`


/**
 * Define the API request schema
 */
const apiSchema = yup.object().shape({
  unitno: yup
    .string()
    .required(),
  unitstatusid: yup
    .string()
    .required(),
  purchasertypeid: yup
    .string()
    .required(),
  country: yup
    .string()
    .required(),
  'buyer[firstname][0]': yup
    .string()
    .required(),
  'buyer[lastname][0]': yup
    .string()
    .required(),
  'buyer[nricpass][0]': yup
    .string()
    .required(),
  'buyer[email][0]': yup
    .string()
    .email().required(),
  'buyer[mobileno][0]': yup
    .string()
    .required(),
  'buyer[nationality][0]': yup
    .string()
    .required(),
  tax_no: yup
    .string()
    .required(),
  address: yup
    .string()
    .required(),
  city: yup
    .string(),
  state: yup
    .string(),
  postcode: yup
    .string()
    .required(),
  payment_method: yup
    .string()
    .required(),
  unit_price: yup
    .string()
    .required(),
  booking_fee: yup
    .string()
    .required(),
  payment_reference: yup
    .string()
    .required(),
  rebate: yup
    .string(),
  car_park: yup
    .string()
    .required(),
  booking_date: yup
    .string()
    .required(),
  price: yup
    .string()
    .required(),
  selling_price: yup
    .string()
    .required(),
  file_nricpass: yup
    .string()
    .required(),
  file_pop: yup
    .string()
    .required(),
  action: yup
    .string()
    .matches(/booking/),
});


/**
 * Create a new unit booking with the API
 */
export function createBooking(booking) {
  return new Promise((resolve, reject) => {
    const data = {
      'unitno': booking.unit.unitId,
      'unitstatusid': 1,
      'purchasertypeid': 1,
      'country': booking.details.country,
      'buyer[firstname][0]': booking.details.firstName,
      'buyer[lastname][0]': booking.details.lastName,
      'buyer[nricpass][0]': booking.details.identityNo,
      'buyer[email][0]': booking.details.emailAddress,
      'buyer[mobileno][0]': booking.details.mobileNo,
      'buyer[nationality][0]': booking.details.country,
      'tax_no': booking.details.taxNo,
      'address': booking.details.address,
      'city': booking.details.city,
      'state': booking.details.state,
      'postcode': booking.details.postcode,
      'payment_method': booking.computed.paymentPlan.id,
      'unit_price': booking.computed.paymentPlan._rawPrice,
      'booking_fee': booking.bookingFee.amount,
      'payment_reference': booking.bookingFee.method,
      'rebate': '',
      'car_park': 0,
      'booking_date': format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      'price': booking.computed.paymentPlan._rawPrice,
      'selling_price': booking.computed.paymentPlan._rawPrice,
      'file_nricpass': booking.details.identityDocUrl,
      'file_pop': booking.bookingFee.receiptUrl,
      'action': 'booking',
    }

    // Validate data
    try {
      apiSchema.validateSync(data)
    }
    catch (errors) {
      reject(errors);
      return;
    }

    // Create the booking with the server
    axios.post(API_ENDPOINT, qs.stringify(data)).then((response) => {
      if (response.data.statustext.toLowerCase() === "success") {
        resolve(response.data.bookingid);
      } else {
        reject(response.data.error);
      }
    });
  })
}