/**
 * @fileoverview A dialog to confirm a unit reservation.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import * as Utils from '@Lib/index';
import * as UnitsActions from '@Stores/units/actions';
import Button from '@Components/Button';


/**
 * Map redux store to props.
 */
const mapStateToProps = (state, ownProps) => ({
  unit: state.units.units.find(u => u.unitno === ownProps.unitId) || null,
  isLoading: state.units.isReserveLoading,
});


/**
 * <ConfirmReservationDialog/> is a modal dialog to confirm a unit reservation.
 */
class ConfirmReservationDialog extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    /** Show this dialog. */
    isOpen: PropTypes.bool,

    /** The id of this unit. */
    unitId: PropTypes.string,

    /** The label of this unit. */
    label: PropTypes.string,
  }

  /**
   * Default Props
   */
  static defaultProps = {
    isOpen: false,
  }

  toggle() {
    if (!this.props.isLoading) {
      this.props.toggle();
    }
  }

  handleSubmit() {
    this.props
      .dispatch(UnitsActions.reserveUnit(this.props.unitId))
      .then(() => this.props.toggle())
      .catch(error => {
        if (/021-/.test(error)) {
          window.alert(`You've exceeded the maximum (5) hold limit.`);
          this.props.dispatch(UnitsActions.setReserveLoading(false));
          this.props.toggle();
        } else {
          Utils.throwComponentDidCatch(this, new Error(error));
        }
      });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this.toggle.bind(this)} centered>
          {/* Modal Header */}
          <ModalHeader>
            Confirm reservation: Unit {this.props.unitId}
          </ModalHeader>

          {/* Modal Body */}
          <ModalBody>
            Your reservation will be held for a maximum of thirty (30) minutes.
          </ModalBody>

          {/* Modal Footer */}
          <ModalFooter>
            <Button
              color="secondary"
              width="sm"
              disabled={this.props.isLoading}
              onClick={this.toggle.bind(this)}
            >
              Cancel
            </Button>
            <Button
              color="cyan"
              width="sm"
              disabled={this.props.isLoading}
              loading={this.props.isLoading}
              onClick={this.handleSubmit.bind(this)}
            >
              Reserve
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default connect(mapStateToProps)(ConfirmReservationDialog);
