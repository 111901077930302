/**
 * @fileoverview A component to display a single unit's info and availability
 * status.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

/**
 * Prop Types
 */
const propTypes = {
  /** Show this row as selected. */
  selected: PropTypes.bool,

  /** Status of this unit. */
  status: PropTypes.oneOf(['available', 'reserved', 'sold', 'error', 'booked']).isRequired,

  /** Indicates whether this unit is currently reserved by the active user. */
  myReservedUnit: PropTypes.bool,

  /** Label of this unit. */
  label: PropTypes.string.isRequired,

  /** Price of this unit. */
  price: PropTypes.string.isRequired,

  /** Called when this unit is selected. */
  onSelect: PropTypes.func,

  /** Called when this unit is reserved. */
  onReserve: PropTypes.func,
}


/**
 * Default Props
 */
const defaultProps = {
  selected: false,
  status: 'error',
  myReservedUnit: false,
  label: 'nil',
  price: 'nil',
  expiryTime: '',
}


/**
 * <UnitRow/> is a component to display a single unit's info and
 * availability status.
 */
function UnitRow(props) {
  return (
    <div className="unit-availability-row" active={props.selected ? '' : undefined}>

      {/* Status */}
      <div className="unit-availability-row__col unit-availability-row__status">
        <span className="unit-availability-row__indicator" status={props.status}></span>
        {props.status}
      </div>

      {/* Label */}
      <div className="unit-availability-row__col unit-availability-row__label">
        {props.label}
      </div>

      {/* Price */}
      <div className="unit-availability-row__col unit-availability-row__price">
        {props.price}
      </div>

      {/* Reservation Expiry */}
      <div className="unit-availability-row__col unit-availability-row__expiry">
        {props.expiryTime !== '' && props.expiryTime >= 0 &&
          <div>
            {props.myReservedUnit && <i>•</i>}
            {props.expiryTime} minute(s) remaining
          </div>
        }
      </div>

      {/* Actions */}
      {(props.status === 'available' || props.myReservedUnit) &&
        <div className="unit-availability-row__col unit-availability-row__actions">
          {/* Reserve Button */}
          {props.status !== 'reserved' && (
            <Button color="outline-secondary" onClick={props.onReserve}>
              Reserve
            </Button>
          )}
          
          {/* Select Button */}
          <Button color="outline-cyan" onClick={(e) => props.onSelect(e, props.label)}>
            {props.selected ? 'Deselect' : 'Select'}
          </Button>
        </div>
      }

    </div>
  )
}

UnitRow.propTypes = propTypes;
UnitRow.defaultProps = defaultProps;

export default UnitRow;
