/**
 * @fileoverview Gets the details of a particular booking.
 */
import axios from 'axios';
import { ROOT_URL } from './constants';
import parse from 'date-fns/parse'


/**
 * Define the API endpoint.
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostgetbookinginfoapi.php`;

/**
 * Get the details of a particular booking from the API.
 */
export function getBookingInfo(bookingId) {
  return new Promise((resolve, reject) => {
    const params = {
      refid: bookingId
    }

    axios.get(API_ENDPOINT, { params: params }).then((response) => {
      if (response.data.error != '') {
        reject(new Error(response.data.error));
        return;
      }

      let bookingInfo = _transform(response.data.booking_info[0]);
      resolve(bookingInfo);
    });
  });
}


/**
 * Transform and clean up incoming data from the server.
 */
function _transform(booking) {
  return {
    firstName: booking.firstname,
    lastName: booking.lastname,
    fullName: `${booking.firstname} ${booking.lastname}`,
    email: booking.email,
    contactNo: booking.mobilecontactno,
    nationality: booking.nationalityText,
    address1: booking.address,
    address2: booking.address2,
    address3: booking.address3,
    city: booking.city,
    country: booking.countryText,
    postcode: booking.postcode,
    taxNo: booking.tax_no,
    identityNo: booking.nricpass,
    nricUrl: booking.file_nricpass,
    bookingDate: parse(booking.booking_date),
    bookingFee: parseInt(booking.booking_fee),
    bookingFeeFull: booking.booking_fee_full,
    bookingFeeBalance: 10000000 - parseInt(booking.booking_fee),
    proofOfPayment: booking.file_pop,
    paymentMethod: booking.payment_method,
    paymentReference: booking.payment_reference,
    sellingPrice: booking.sellingprice,
  }
}
