/**
 * Merge units with their position data.
 * @param {Array<Object>} units
 * @param {Object.<string, Object>} position The position data returned from the
 *  `getPosition` method.
 */
export function mergePosition(units, position) {
  return units.map(unit => {
    if (position.hasOwnProperty(unit.unitno)) {
      return { ...unit, ...position[unit.unitno] };
    }
    return { ...unit, position: null, size: null };
  });
}
