import axios from 'axios';
import qs from 'query-string';
import { ROOT_URL } from './constants';

/**
 * API endpoint.
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostemailunitapi.php`;

/**
 * Send one or more unit's information to an email address.
 * @param {string} email
 * @param {string[]} unitIds A list of unit ids.
 */
export function emailUnitInformation(email, unitIds) {
  return new Promise((resolve, reject) => {
    const params = {
      email: email,
      unitno: unitIds.join(','),
    };

    axios.post(API_ENDPOINT, qs.stringify(params)).then(response => {
      if (response.data.statustext.toLowerCase() === 'success') {
        resolve();
      } else {
        reject(response.data.error);
      }
    });
  });
}
