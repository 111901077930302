import _ from 'lodash';
import axios from 'axios';
import { ROOT_URL } from './constants';

/**
 * Define the API endpoint.
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostunitpricesapi.php`;


/**
 * Get list of available payment plans from the API.
 * @param {string} unitId
 */
export function getPaymentPlans(unitId) {
  return new Promise((resolve, reject) => {
    if (!unitId) {
      reject('No unit no. provided');
      return;
    }

    const params = {
      'filter_by_unitno': unitId,
    }

    axios.get(API_ENDPOINT, { params: params }).then((response) => {
      if (!_.has(response.data, 'units[0].priceList')) {
        reject(new Error(`Unable to resolve price list for unit '${unitId}'`));
        return;
      }

      const plans = response.data.units[0].priceList
        .map(_transformData)
        .filter(_filterData);

      resolve(plans);
    });
  });
}


/**
 * Transform API response to native JS types
 * @param {Object} plan
 */
function _transformData(plan) {

  // Transform price into formatted string. ie. 9000 > 9,000
  plan._rawPrice = plan.price;
  plan.price = parseInt(plan.price).toLocaleString('en-US');

  // Use `code2` as primary id
  plan.id = plan.code2;

  return plan;
}


/**
 * Filter unneeded data
 */
function _filterData(plan) {
  if (parseInt(plan.price) === 0) {
    return false;
  }
  return true;
}
