/**
 * @fileoverview A form to input booking fee details.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { Form } from 'formik';

import Field, { CleaveField } from '@Components/Field';
import ErrorLabel from '@Components/ErrorLabel';

/**
 * Prop Types
 */
const propTypes = {
  balancePayable: PropTypes.string,
}

/**
 * Default Props
 */
const defaultProps = {
  balancePayable: 'IDR 2,000,000 – IDR 10,000,000'
}


/**
 * <BookingFeeForm/> is a form to input booking fee details.
 */
function BookingFeeForm(props) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  return (
    <Form>

      {/* Amount Due */}
      <div className="booking-fee-amount d-flex justify-content-between">
        <span className="booking-fee-amount__title">Booking Fee Balance</span>
        <span className="booking-fee-amount__price">{props.balancePayable}</span>
      </div>

      {/* Payment Amount */}
      <FormGroup>
        <Label for="amount">Payment Amount</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">IDR</InputGroupAddon>
          <CleaveField
            name="amount"
            placeholder="Enter payment amount"
            options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
            useRaw={true}
          />
        </InputGroup>
        <ErrorLabel name="amount" {...props}/>
      </FormGroup>

      {/* Payment Method */}
      <FormGroup>
        <Label for="method">Payment Method</Label>
        <Field name="method" type="select">
          <option value="Cash">Cash</option>
          <option value="Credit Card">Credit Card</option>
          <option value="Debit Card">Debit Card</option>
          <option value="Bank Transfer">Bank Transfer</option>
        </Field>
        <ErrorLabel name="method" {...props}/>
      </FormGroup>

      {/* Proof of Payment */}
      <FormGroup className="booking-fee-upload">
        <Label for="receipt">Upload Proof of Payment</Label>
        <Input
          className="booking-fee-upload__input"
          id="receipt"
          name="receipt"
          type="file"
          onChange={(event) => {
            const objectURL = URL.createObjectURL(event.currentTarget.files[0]);
            props.setFieldValue('receipt', objectURL)
          }}
        />
        <Label for="receipt" className="booking-fee-upload__container">
          <div>
            {
              values.receipt
                ? <img src={values.receipt}/>
                : <span>Select a photo</span>
            }
          </div>
        </Label>
        <ErrorLabel name="receipt" {...props}/>
      </FormGroup>

    </Form>
  )
}

BookingFeeForm.propTypes = propTypes;
BookingFeeForm.defaultProps = defaultProps;

export default BookingFeeForm;
