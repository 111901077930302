/**
 * Manually throw an error to trigger componentDidCatch in event boundaries.
 * This function is a hack to force componentDidCatch to catch errors in async
 * handlers.
 * @param {React.Component} component
 * @param {Error} error
 */
export default function throwComponentDidCatch(component, error) {
  component.setState(() => { throw error });
}
