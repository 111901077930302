/**
 * @fileoverview A convenience function to help reduce reducer boilerplate.
 */


/**
 * A convenience function to help reduce reducer boilerplate.
 * @param {Object} initialState
 * @param {Object.<string, Function>} handlers
 */
function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}


export default createReducer;