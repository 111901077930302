/**
 * @fileoverview Defines a function to upload a Object URL based file to the
 * server.
 */
import axios from 'axios';
import { ROOT_URL } from './constants';
import * as Utils from '@Lib/index'

/**
 * API Endpoint
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostuploadfilesapi.php`;


/**
 * Upload a Object URL based file to the server.
 */
export function uploadObjectUrl(blobUrl) {
  return new Promise((resolve, reject) => {
    const instance = axios.create();
    instance.defaults.params = {
      user: null,
      pass: null,
      site_password: null
    };

    instance
      .get(blobUrl, { responseType: 'blob' })
      .then(response => response.data)
      .then(blob => {
        const formData = new FormData();
        const extensions = {
          'image/jpeg': '.jpg',
          'image/png': '.png',
          'image/gif': '.gif',
          'image/bmp': '.bmp',
        }
        formData.append('file', blob, Utils.generateRandomId() + extensions[blob.type]);
        return formData;
      })
      .then(formData => {
        return axios.post(API_ENDPOINT, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      })
      .then(response => {
        resolve(response.data.filepath);
      })
      .catch(error => reject(error));
  });
}
