/**
 * @fileoverview A form to input buyer details.
 */
import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { Form } from 'formik';

import Field from '@Components/Field';
import ErrorLabel from '@Components/ErrorLabel';

/** DetailsForm is a form to input buyer details. */
function DetailsForm(props) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  return (
    <Form>

      <Row>
        {/* First Name */}
        <Col>
          <FormGroup>
            <Label>First Name</Label>
            <Field name="firstName"/>
            <ErrorLabel name="firstName" {...props} />
          </FormGroup>
        </Col>

        {/* Last Name */}
        <Col>
          <FormGroup>
            <Label>Last Name</Label>
            <Field name="lastName"/>
            <ErrorLabel name="lastName" {...props} />
          </FormGroup>
        </Col>
      </Row>

      {/* Mobile Number */}
      <FormGroup>
        <Label>Mobile Number</Label>
        <Field name="mobileNo" type="tel"/>
        <ErrorLabel name="mobileNo" {...props} />
      </FormGroup>

      {/* Email Address */}
      <FormGroup>
        <Label>Email Address</Label>
        <Field name="emailAddress" type="email"/>
        <ErrorLabel name="emailAddress" {...props} />
      </FormGroup>

      <Row>
        {/* Country */}
        <Col>
          <FormGroup>
            <Label>Country</Label>
            <Field name="country" type="select">
              {props.countries.map((country) =>(
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </Field>
            <ErrorLabel name="country" {...props} />
          </FormGroup>
        </Col>

        {/* State */}
        <Col>
          {props.values.country === '100' &&
            <FormGroup>
              <Label>State</Label>
              <Field name="state" type="select">
                <option value=""></option>
                <option value="Aceh">Aceh</option>
                <option value="Bali">Bali</option>
                <option value="Bangka">Bangka</option>
                <option value="Banten">Banten</option>
                <option value="Bengkulu">Bengkulu</option>
                <option value="Central Java">Central Java</option>
                <option value="Central Kalimantan">Central Kalimantan</option>
                <option value="Central Sulawesi">Central Sulawesi</option>
                <option value="Coat of arms of East Java">Coat of arms of East Java</option>
                <option value="East Kalimantan">East Kalimantan</option>
                <option value="East Nusa Tenggara">East Nusa Tenggara</option>
                <option value="Jakarta">Jakarta</option>
                <option value="Jambi">Jambi</option>
                <option value="Lambang Propinsi">Lambang Propinsi</option>
                <option value="Lambang Provinsi Papua Barat">Lambang Provinsi Papua Barat</option>
                <option value="Lambang Riau">Lambang Riau</option>
                <option value="Lampung">Lampung</option>
                <option value="Maluku">Maluku</option>
                <option value="North Maluku">North Maluku</option>
                <option value="North Sulawesi">North Sulawesi</option>
                <option value="North Sumatra">North Sumatra</option>
                <option value="Papua">Papua</option>
                <option value="Riau">Riau</option>
                <option value="South Kalimantan">South Kalimantan</option>
                <option value="South Sulawesi">South Sulawesi</option>
                <option value="South Sumatra">South Sumatra</option>
                <option value="Southeast Sulawesi">Southeast Sulawesi</option>
                <option value="West Java">West Java</option>
                <option value="West Kalimantan">West Kalimantan</option>
                <option value="West Nusa Tenggara">West Nusa Tenggara</option>
                <option value="West Sulawesi">West Sulawesi</option>
                <option value="West Sumatra">West Sumatra</option>
                <option value="Yogyakarta">Yogyakarta</option>
              </Field>
              <ErrorLabel name="state" {...props} />
            </FormGroup>
          }
        </Col>

        {/* City */}
        <Col>
          <FormGroup>
            <Label>City</Label>
            <Field name="city"/>
            <ErrorLabel name="city" {...props} />
          </FormGroup>
        </Col>
      </Row>

      {/* Address */}
      <FormGroup>
        <Label>Address</Label>
        <Field name="address" type="textarea"/>
        <ErrorLabel name="address" {...props} />
      </FormGroup>

      {/* Postcode */}
      <FormGroup>
        <Label>Postcode</Label>
        <Field name="postcode" type="tel"/>
        <ErrorLabel name="postcode" {...props} />
      </FormGroup>

      {/* Tax Number */}
      <FormGroup>
        <Label>Tax Number</Label>
        <Field name="taxNo" type="tel"/>
        <ErrorLabel name="taxNo" {...props} />
      </FormGroup>

      {/* KPT/Passport Number */}
      <FormGroup>
        <Label>KPT/Passport Number</Label>
        <Field name="identityNo" type="tel"/>
        <ErrorLabel name="identityNo" {...props} />
      </FormGroup>

      {/* Identity Document */}
      <FormGroup className="booking-fee-upload">
        <Label for="identityDoc">Upload Identity Document</Label>
        <Input
          className="booking-fee-upload__input"
          id="identityDoc"
          name="identityDoc"
          type="file"
          onChange={(event) => {
            const objectURL = URL.createObjectURL(event.currentTarget.files[0]);
            props.setFieldValue('identityDoc', objectURL)
          }}
        />
        <Label for="identityDoc" className="booking-fee-upload__container">
          <div>
            {values.identityDoc ? (
              <img src={values.identityDoc} />
            ) : (
              <span>Select a photo</span>
            )}
          </div>
        </Label>
        <ErrorLabel name="identityDoc" {...props}/>
      </FormGroup>

    </Form>
  )
}

export default DetailsForm;
