/**
 * @fileoverview Defines a fallback component to display in the event of any
 * errors within its child component tree.
 */
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import SVGIcon from '@Components/SVGIcon';

/**
 * <ErrorBoundary/> is a component to display a fallback component in the event
 * of any errors within its child component tree.
 */
class ErrorBoundary extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    render: PropTypes.func,
  }

  /**
   * Default Props
   */
  static defaultProps = {
    render: null,
  }

  /**
   * Initial State
   */
  state = {
    hasError: false,
    error: '',
    info: '',
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error.message ? error.message : 'Unknown error',
      info: info.componentStack.trim(),
    });
  }

  render() {
    if (!this.state.hasError) return this.props.children;
    return (
      <div className="error-boundary">
        <h3 className="error-boundary__title">
          The app encountered an error
        </h3>
        <h4 className="error-boundary__error">
          <em>Error: </em>{this.state.error}
        </h4>
        <p className="error-boundary__info">{this.state.info}</p>
      </div>
    );
  }
}

export default ErrorBoundary;
