/**
 * @fileoverview Defines a component to filter the unit list.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Button from '@Components/Button';


/**
 * Prop Types
 */
const propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    filter: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    counter: PropTypes.number,
  })).isRequired,
  value: PropTypes.oneOf(['all', 'available', 'reserved']),
  onFilter: PropTypes.func,
}


/**
 * Default Props
 */
const defaultProps = {
  items: [],
}


/**
 * `<UnitFilter/>` is a component to to select a unit filter.
 */
function UnitFilter(props) {
  return (
    <Container fluid>
      <div className="unit-filter">
        {props.items.map(option =>
          <span key={option.filter} className="unit-filter__item">
            <Button
              color="outline-secondary"
              onClick={() => props.onFilter(option.filter)}
              active={props.value === option.filter}
            >
              {option.label} <span>({option.counter})</span>
            </Button>
          </span>
        )}
      </div>
    </Container>
  )
}

UnitFilter.propTypes = propTypes;
UnitFilter.defaultProps = defaultProps;

export default UnitFilter;
