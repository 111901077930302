/**
 * @fileoverview A screen to manage bookings.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';

import * as Utils from '@Lib/index';
import * as BookingActions from '@Stores/bookings/actions';
import Button from '@Components/Button';
import SVGIcon from '@Components/SVGIcon';

import ConvertBookingScreen from './ConvertBookingScreen';
import SuccessScreen from './SuccessScreen';


/**
 * Map redux store to props.
 */
const mapStateToProps = (state) => {
  const all = state.bookings.bookingIds.map(id => state.bookings.bookings[id]);
  return {
    user: { username: state.meta.username, password: state.meta.password },
    bookings: all.filter(b => b.status === 'booked'),
    sold: all.filter(b => b.status === 'sold'),
    isLoading: state.meta.loading.bookings === true
  }
}


/**
 * <ManageBooking/> is a route to manage bookings.
 */
class ManageBooking extends Component {

  /**
   * Initial State
   */
  state = {
    ready: false,
    filter: 'booked', // or 'sold'
  }

  componentDidMount() {
    // Load user's booking list from API
    if (this.props.bookings.length < 1) {
      this.props
        .dispatch(BookingActions.getBookings())
        .then(() => this.setState({ ready: true }))
        .catch(err => Utils.throwComponentDidCatch(this, new Error(err)));
    }
  }

  handleSelect(bookingId) {
    if (bookingId !== this.props.match.params.id) {
      this.props.history.push(`/manage-booking/${bookingId}`);
    }
  }

  handleFilter(filter) {
    if (filter !== this.state.filter) {
      this.setState({ filter: filter });
      if (this.props.match.params.id !== undefined) {
        this.props.history.push(`/manage-booking`);
      }
    }
  }

  _getTimeAGo(bookingDate) {
    return distanceInWordsStrict(bookingDate, new Date())
  }

  /**
   * Render Function
   */
  render() {

    const bookings = this.state.filter === 'booked'
      ? this.props.bookings
      : this.props.sold;

    return (
      <div className="manage-bookings">

        <div className="manage-bookings__col manage-bookings__left">
          <div className="booking-list-toggle">
            <Button
              color="outline-secondary"
              active={this.state.filter === 'booked' ? true : undefined}
              onClick={() => this.handleFilter('booked')}
            >
              Bookings ({this.props.bookings ? this.props.bookings.length : ''})
            </Button>
            <Button
              color="outline-secondary"
              active={this.state.filter === 'sold' ? true : undefined}
              onClick={() => this.handleFilter('sold')}
            >
              Sold ({this.props.sold ? this.props.sold.length : ''})
            </Button>
          </div>

          {bookings &&
            <ul className="booking-list">
              {bookings.map(booking =>
                <li
                  key={booking.bookingId}
                  className="booking-list-item"
                  open={this.props.match.params.id == booking.bookingId ? true : undefined}
                  onClick={() => this.handleSelect(booking.bookingId)}
                >
                  <button>
                    {/* Content */}
                    <div className="booking-list-item__content">
                      <span className="booking-list-item__name">
                        {booking.buyerName} {booking.status === 'sold' && <span className="badge badge-danger">SOLD</span>}
                      </span>
                      <span className="booking-list-item__details">
                        {booking.unitNo}&ensp;•&ensp;{booking.cluster}&ensp;•&ensp;{this._getTimeAGo(booking.createdAt)} ago
                      </span>
                    </div>
  
                    {/* Chevron */}
                    <span className="booking-list-item__chevron">
                      <SVGIcon icon="chevron" small/>
                    </span>
                  </button>
                </li>
              )}
            </ul>
          }

          {/* Empty State */}
          {bookings.length < 1 &&
            <div className="booking-list-empty">
              <span className="booking-list-empty__title">No bookings yet</span>
              {this.state.filter === 'sold'
                ? (<p>Once you confirm a booking,<br/>you'll see them here.</p>)
                : (<p>Once you make a new booking,<br/>you'll see them here.</p>)
              }
            </div>
          }
        </div>

        <div className="manage-bookings__col manage-bookings__right">
          <Switch location={this.props.location}>

            {/* Default Route */}
            <Route exact path="/manage-booking" render={(props) =>
              <div className="manage-bookings__empty">
                <img src="img/lavon-swan-grey.svg" role="presentation"/>
              </div>
            }/>

            {/* Manage Booking */}
            <Route exact path="/manage-booking/:id" render={(props) => (this.state.ready &&
              <div>
                <ConvertBookingScreen
                  {...props}
                  bookingId={props.match.params.id}
                  isLoading={this.props.isLoading}
                />
              </div>
            )}/>

            {/* Success Screen */}
            <Route exact path="/manage-booking/:id/success" render={(props) =>(
              <SuccessScreen
                {...props}
                bookingId={props.match.params.id}
              />
            )}/>

          </Switch>
        </div>

      </div>
    )
  }

}

export default connect(mapStateToProps)(ManageBooking);
