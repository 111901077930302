/**
 * @fileoverview Defines action creators to interact with the `meta` store.
 */
import * as PricechartService from '@Services/PricechartService';


/**
 * Action Types
 */
export const ACTION_TYPES = {
  SET_AUTH: 'SET_AUTH',
  LOGOUT: 'LOGOUT',
  SET_META_LOADING: 'SET_META_LOADING',
  SET_COUNTRY_LIST: 'SET_COUNTRY_LIST',
}


/**
 * [Thunk] Retrieve list of countries from the API and store it.
 */
export function getCountries() {
  return dispatch => {
    dispatch(setLoading('countries', true));
    PricechartService.getCountries().then(countries => {
      dispatch(setCountries(countries));
      dispatch(setLoading('countries', false));
    });
  }
}


/**
 * Return an action to set the list of countries.
 */
export function setCountries(countries) {
  return {
    type: ACTION_TYPES.SET_COUNTRY_LIST,
    payload: countries
  }
}

/**
 * Sets the authentication credentials in the store.
 */
export function setAuth(username, password) {
  return {
    type: ACTION_TYPES.SET_AUTH,
    payload: { username, password }
  }
}


/**
 * Logouts the currently signed in user.
 */
export function logout() {
  return {
    type: ACTION_TYPES.LOGOUT,
  }
}

/**
 * Return an action to set the loading state.
 */
export function setLoading(id, value) {
  return {
    type: ACTION_TYPES.SET_META_LOADING,
    payload: { id, value }
  }
}
