/**
 * @fileoverview Implements a component that calls a callback when the element
 * enters the viewport.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * `<InView/> is a component that calls a callback when the element enters the
 * viewport.
 */
class InView extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    active: PropTypes.bool,
    once: PropTypes.bool,
    onEnter: PropTypes.func,
  }

  /**
   * Default Props
   */
  static defaultProps = {
    active: true,
    once: false,
  }

  /**
   * The IntersectionObserver instance.
   *
   * @type IntersectionObserver
   */
  _intersectionObserver = null;

  constructor() {
    super();
    this._intersectionObserver = new IntersectionObserver(this.onEnter.bind(this));
    this._ref = React.createRef();
  }

  onEnter(entries, observer) {
    if (!this.props.active) {
      return;
    }

    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // Call the callback
        if (this.props.onEnter) {
          this.props.onEnter(entry);
        }

        // Unobserve the element if set to trigger only once
        if (this.props.once) {
          observer.unobserve(entry.target);
        }
      }
    });
  }

  componentDidMount() {
    this._intersectionObserver.observe(this._ref.current);
  }

  render() {
    return (
      <span ref={this._ref}></span>
    )
  }
}

export default InView;
