/**
 * @fileoverview Defines the root react element.
 */
import React, { Component } from 'react';
import 'intersection-observer';
import qs from 'query-string';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';

import store from '@Stores/store';
import * as MetaActions from '@Stores/meta/actions';
import * as UnitActions from '@Stores/units/actions';

import ScreenLoadingIndicator from '@Components/ScreenLoadingIndicator';
import { withErrorBoundary } from '@Components/ErrorBoundary';
import CreateBooking from '@Screens/CreateBooking';
import ManageBooking from '@Screens/ManageBooking';

/**
 * <App/> is the root react element.
 */
class App extends Component {

  state = {
    isLoading: true,
  }

  /**
   * @constructor
   */
  constructor(props) {
    super(props);
    this.init();
  }

  /**
   * Execute the app startup script.
   */
  init() {
    const promises = [];

    // Load authentication credentials from query string
    const parsed = qs.parse(location.search);
    if (_.has(parsed, 'username') && _.has(parsed, 'pass')) {
      store.dispatch(MetaActions.setAuth(parsed.username, parsed.pass));
    } else {
      throw new Error('No authentication info provided.');
    }

    // Configure axios
    const meta = store.getState().meta;
    axios.defaults.params = {};
    axios.defaults.params.user = meta.username;
    axios.defaults.params.pass = meta.password;
    axios.defaults.params.site_password = 'ffs12345';

    // Load list of countries
    promises.push(store.dispatch(MetaActions.getCountries()));

    // Load list of prospects
    promises.push(store.dispatch(UnitActions.getProspects()));

    // Set active cluster
    if (_.has(parsed, 'filter_by_block')) {
      let cluster = parsed.filter_by_block.toLowerCase();
      cluster = cluster.replace(/\//, '');
      store.dispatch(UnitActions.setCluster(cluster));
      promises.push(store.dispatch(UnitActions.getClusterData(cluster)));
    }

    // Update state when all promises are resolved
    Promise.all(promises).then(() => {
      this.setState({ isLoading: false });
    });
  }

  /**
   * Render Function
   */
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <ScreenLoadingIndicator show={this.state.isLoading}/>

            {!this.state.isLoading &&
              <div>
                {/* Default Route */}
                <Route exact path="/" render={() => (
                  <Redirect to="/create-booking"/>
                )}></Route>
  
                {/* Create Booking */}
                <Route path="/create-booking" component={(props) => (
                  <CreateBooking {...props}/>
                )}/>
  
                {/* Manage Booking */}
                <Route path="/manage-booking/:id?" component={(props) => (
                  <ManageBooking {...props}/>
                )}/>
              </div>
            }
          </div>
        </Router>
      </Provider>
    )
  }

}


export default withErrorBoundary(App);
