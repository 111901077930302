/**
 * @fileoverview A component to display a loader icon.
 */
import React from 'react';
import PropTypes from 'prop-types';


/**
 * Prop Types
 */
const propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
}


/**
 * Default Props
 */
const defaultProps = {
  size: 'md',
}


/**
 * <LoaderIcon/> is a component to display a loader icon.
 */
function LoaderIcon(props) {
  let cn = '';
  switch (props.size) {
    case 'sm':
      cn = 'la-sm'; break;
    case 'lg':
      cn = 'la-2x'; break;
  }

  return (
    <span className={`la-ball-clip-rotate ${cn}`}>
      <div></div>
    </span>
  )
}

LoaderIcon.propTypes = propTypes;
LoaderIcon.defaultProps = defaultProps;

export default LoaderIcon;
