/**
 * @fileoverview A screen to select a unit.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getUnits, setUi } from '@Stores/units/actions';

import ScreenLoadingIndicator from '@Components/ScreenLoadingIndicator';
import DialogFooter from '@Components/DialogFooter';
import UnitListPicker from '@Components/UnitListPicker';
import UnitMapPicker from '@Components/UnitMapPicker';
import Button from '@Components/Button';
import SVGIcon from '@Components/SVGIcon';
import * as Utils from '@Lib/index';


/**
 * Map redux store to props.
 */
const mapStateToProps = (state, ownProps) => ({
  cluster: state.units.cluster,
  clusterData: state.units.clusterData[state.units.cluster],
  units: state.units.units,
  isLoading: state.units.loading,
  ui: state.units.ui,
});


/**
 * `<SelectUnitScreen/>` is a screen for selecting units.
 */
class SelectUnitScreen extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    cluster: PropTypes.string,
    units: PropTypes.array,
    reserveUnits: PropTypes.array,
    isLoading: PropTypes.bool,
    ui: PropTypes.oneOf(['list', 'map'])
  }

  /**
   * Default Props
   */
  static defaultProps = {
    units: [],
    reserveUnits: [],
    isLoading: false,
  }

  /**
   * Initial State
   */
  state = {
    unit: null,
    reserveUnits: null,
    error: null,
  }

  componentDidMount() {
    // Load list of units from the API
    if (this.props.units.length < 1) {
      this.props
        .dispatch(getUnits(this.props.cluster))
        .catch(err => Utils.throwComponentDidCatch(this, new Error(err)));
    }
  }

  componentWillMount() {
    this.setState({ unit: this.props.value });
  }

  next() {
    if (this.state.unit === null) {
      this.props.setPageValidity(false);
      Utils.triggerCssClass('.footer__next-btn', ['shake', 'btn-danger'], 820);
      this.setState({ error: 'Please select a unit' });
      return;
    }
    this.setState({ error: null });
    this.props.onSubmit(this.state.unit);
    this.props.setPageValidity(true);
    this.props.setActivePage('2');
  }

  handleSelect(unitId) {
    this.setState((prevState) => ({
      unit: prevState.unit === unitId ? null : unitId, // Deselect if already selected
      error: null
    }));
  }

  refreshList() {
    this.props.dispatch(getUnits(this.props.cluster));
  }

  switchUi = (ui) => {
    this.props.dispatch(setUi(ui));
  }

  renderLeftFooter = (props) => {
    return (
      <div className="select-unit-ui-toggler">
        <Button
          className="select-unit-ui-toggler__btn"
          width="sm"
          active={this.props.ui === 'map' ? true : false}
          onClick={() => this.switchUi('map')}
        >
          Map View
        </Button>
        <Button
          className="select-unit-ui-toggler__btn"
          width="sm"
          active={this.props.ui === 'list' ? true : false}
          onClick={() => this.switchUi('list')}
        >
          List View
        </Button>
        &emsp;
        <Button
          color="secondary"
          width="sm"
          onClick={() => this.refreshList()}
        >
          <SVGIcon
            icon="refresh"
            color="white"
            style={{
              width: '20px',
              height: '20px',
              position: 'relative',
              top: '4px',
            }}
          /> Refresh
        </Button>
      </div>
    )
  }

  renderLeftFooterRefresh = (props) => {
    return (
      <div>
        <Button
          color="secondary"
          width="sm"
          active={this.props.ui === 'list' ? true : false}
          onClick={() => this.refreshList()}
        >
          Refresh
        </Button>
      </div>
    )
  }

  /**
   * Render function.
   */
  render() {
    return (
      <div className="create-booking">
        
        {/* Loading Indicator */}
        <ScreenLoadingIndicator
          show={this.props.isLoading}
        />

        {/* Unit Availability List */}
        {this.props.ui === 'list' &&
          <UnitListPicker
            value={this.state.unit}
            units={this.props.units}
            onSelect={this.handleSelect.bind(this)}
          />
        }

        {/* Unit Availability Map */}
        {this.props.ui === 'map' &&
          <UnitMapPicker
            value={this.state.unit}
            units={this.props.units}
            clusterMap={this.props.clusterData.clusterMap}
            onSelect={this.handleSelect.bind(this)}
          />
        }

        {/* Footer */}
        <DialogFooter
          prev={false}
          nextFn={this.next.bind(this)}
          renderLeft={this.renderLeftFooter}
          renderRight={() => {
            return this.state.error
              ? <span className="footer__error">{this.state.error}</span>
              : null
          }}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps)(SelectUnitScreen);
