/**
 * @fileoverview This file defines how the bookings state should change in
 * response to actions sent to the store.
 */

import createReducer from '@Lib/createReducer';
import { ACTION_TYPES as ACTIONS } from './actions';
import _ from 'lodash';


/**
 * Initial State
 */
const initialState = {
  bookings: {},
  bookingIds: [],
}


/**
 * This function defines how the bookings state should change in response to
 * actions sent to the store.
 */
const bookingsReducer = createReducer(initialState, {

  /** SET_BOOKINGS */
  [ACTIONS.SET_BOOKINGS]: (state, action) => {

    // Flatten to an array of booking IDs
    const bookingIds = _.uniq([
      ...state.bookingIds,
      ...action.payload.map(b => b.bookingId)
    ]);

    // Flatten list of bookings to a key value structure
    const bookings = action.payload.reduce((acc, booking) => {
      acc[booking.bookingId] = booking;
      return acc;
    }, state.bookings);

    return { ...state, bookings, bookingIds }
  },

  /** REPLACE_BOOKING */
  [ACTIONS.REPLACE_BOOKING]: (state, action) => {
    return {
      ...state,
      bookings: {
        ...state.bookings,
        [action.payload.bookingId]: action.payload
      }
    }
  },

});

export default bookingsReducer;
