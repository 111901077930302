/**
 * @fileoverview A dialog to select prospects.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import * as Utils from '@Lib/index';
import Button from '@Components/Button';
import SVGIcon from '@Components/SVGIcon';


class SelectProspectDialog extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    open: PropTypes.bool,
    prospects: PropTypes.array,
    toggle: PropTypes.func,
    onSelect: PropTypes.func,
  }

  /**
   * Default Props
   */
  static defaultProps = {
    open: false,
  }

  render() {
    return (
      <Modal
        className='prospect-list-dialog'
        isOpen={this.props.open}
        toggle={this.props.toggle}
        centered
      >
        {/* Modal Header */}
        <ModalHeader>
          Select a Prospect
        </ModalHeader>

        {/* Modal Body */}
        <ModalBody>

          {/* Prospects List */}
          {this.props.prospects.length > 0 &&
            <ul className="prospect-list">
              {this.props.prospects.map(prospect =>
                <li key={prospect.id}>
                  <button onClick={() => this.props.onSelect(prospect.id)}>
                    {/* Content */}
                    <div className="prospect-list__content">
                      {prospect.firstName} {prospect.lastName}
                    </div>

                    {/* Chevron */}
                    <span className="prospect-list__chevron">
                      <SVGIcon icon="chevron" small/>
                    </span>
                  </button>
                </li>
              )}
            </ul>
          }

          {/* Empty State */}
          {this.props.prospects.length < 1 &&
            <div className="prospect-list-empty">
               <span className="prospect-list-empty__title">You don't have any prospects</span>
               <br/>
               <Button color="outline-secondary" width="lg" onClick={this.props.toggle.bind(this)}>
                 Close
               </Button>
            </div>
          }

        </ModalBody>
      </Modal>
    )
  }

}

export default SelectProspectDialog;
