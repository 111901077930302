/**
 * @fileoverview A component that wraps a Reactstrap <Input/> element with
 * Formik's <Field/>
 */
import React from 'react';
import { Input } from 'reactstrap';
import { Field as FormikField } from 'formik';

/**
 * Field is a component that wraps a Reactstrap <Input/> element with Formik's
 * <Field/> element.
 */
function Field(props) {
  return <FormikField component={InnerComponent} {...props} />
}

const InnerComponent = (props) => {
  return (
    <Input
      type={props.type}
      id={props.field.name}
      name={props.field.name}
      value={props.field.value}
      invalid={
        props.form.errors[props.field.name] && props.form.touched[props.field.name]
          ? true
          : undefined
      }
      onChange={props.field.onChange}
      onBlur={props.field.onBlur}
      children={props.children}
    />
  )
}

export default Field;
