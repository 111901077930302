/**
 * @fileoverview A screen to enter booking fee payment details.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { withFormik } from 'formik';
import * as Utils from '@Lib/index';
import { Container, Button } from 'reactstrap';
import DialogFooter from '@Components/DialogFooter';
import BookingFeeForm from './BookingFeeForm';


/** Prop Types */
const propTypes = {
  /** The form values. */
  value: PropTypes.object.isRequired,

  /** Call this function to navigate between pages. */
  setActivePage: PropTypes.func.isRequired,

  /** Call this function to set a page's validity. */
  setPageValidity: PropTypes.func.isRequired,
  
  /** Call this function to update the bookingContext store. */
  onSubmit: PropTypes.func.isRequired,
}


/** Validation Schema */
const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .required('This field is required.')
    .min(2000000, 'Amount has to be IDR 2,000,000 or higher.')
    .max(10000000, 'Amount cannot be more than IDR 10,000,000.'),
  method: yup
    .string()
    .oneOf(['Cash', 'Credit Card', 'Bank Transfer', 'Debit Card']),
  receipt: yup
    .string()
    .required('This field is required.')
})


/**
 * BookingFeeScreen is a screen component to enter booking fee payment details.
 */
class BookingFeeScreen extends Component {

  // Public Methods

  prev() {
    this.props.setActivePage('3');
    this.props.onSubmit(this.props.values);

    // Manually validate values
    validationSchema.isValid(this.props.values).then((isValid) => {
      this.props.setPageValidity(isValid);
    });
  }

  next() {
    this.props.submitForm();

    // Manually validate values
    validationSchema.isValid(this.props.values).then((isValid) => {
      if (!isValid) {
        Utils.triggerCssClass('.footer__next-btn', ['shake', 'btn-danger'], 820);
      }
    });
  }

  // Render Method

  render() {
    return (
      <div className="create-booking">
        <Container className="tight">
          {/* Booking Fee Form */}
          <BookingFeeForm {...this.props}/>

          {/* Footer */}
          <DialogFooter
            nextFn={this.next.bind(this)}
            prevFn={this.prev.bind(this)}
          />
        </Container>
      </div>
    )
  }
}


/**
 * Formik wrapped HOC of BookingFeeScreen.
 */
const FormikBookingFeeScreen = withFormik({

  /** Map initial props to values */
  mapPropsToValues: (props) => {
    return props.value;
  },

  /** Handle form submission */
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(false);
    props.onSubmit(values);
    props.setActivePage('5');
    props.setPageValidity(true);
  },

  /**
   * Specify the form validation schema using a Yup schema.
   * @reference https://github.com/jquense/yup
   */
  validationSchema: validationSchema,

})(BookingFeeScreen);

export default FormikBookingFeeScreen;