/**
 * @fileoverview UnitMapTooltip is a tooltip component used to display
 * information about a specific unit availability.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@Components/Button';


/**
 * Maps status id to label shown.
 */
const __statusLabelMapping = {
  'available': 'Available',
  'reserved': 'Reserved',
  'sold': 'Sold',
  'unavailable': 'Unavailable'
}


/**
 * <UnitMapTooltip/> is a tooltip component to display information about a unit.
 */
class UnitMapTooltip extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    show: PropTypes.bool.isRequired,
    unitId: PropTypes.string.isRequired,
    label: PropTypes.string,
    status: PropTypes.string,
    position: PropTypes.arrayOf(PropTypes.number),
    isSelected: PropTypes.bool.isRequired,
    isReserveEnabled: PropTypes.bool,
    isSelectEnabled: PropTypes.bool,
    setVisibility: PropTypes.func.isRequired,
    onReserve: PropTypes.func,
    onSelect: PropTypes.func,
    onCompare: PropTypes.func,
  }

  /**
   * Default Props
   */
  static defaultProps = {
    isReserveEnabled: true,
    isSelectEnabled: true,
  }

  /**
   * Initial State
   */
  state = {
    prevPos: null,
  }

  constructor(props) {
    super(props);
    this.tooltipRef = React.createRef();
  }

  static getDerivedStateFromProps(props) {
    return props.show && props.position
      ? { prevPos: props.position }
      : null;
  }

  componentDidMount() {
    window.addEventListener('click', this.handleDocumentClick, false);
  }
  
  componentWillUnmount() {
    window.removeEventListener('click', this.handleDocumentClick, false);
  }

  handleDocumentClick = (e) => {
    // Ignore if it's a click on the tooltip element itself
    if (!this.tooltipRef.current.contains(e.target)) {
      this.props.setVisibility(false);
    }
  }

  handleSelect = (e) => {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.unitId);
    }
  }

  handleDeselect = (e) => {
    if (this.props.onSelect) {
      this.props.onSelect(null);
    }
  }

  handleReserve = (e) => {
    if (this.props.onReserve) {
      this.props.onReserve(this.props.unitId);
    }
  }

  handleCompare = (e) => {
    if (this.props.onCompare) {
      this.props.onCompare(this.props.unitId);
    }
  }

  /**
   * Render Function
   */
  render() {

    let left, top;
    if (this.props.show) {
      left = this.props.position[0];
      top = this.props.position[1];
    } else if (this.state.prevPos) {
      left = this.state.prevPos[0];
      top = this.state.prevPos[1];
    } else {
      left = 0;
      top = 0;
    }

    return (
      <div
        ref={this.tooltipRef}
        className="unit-map-tooltip"
        show={this.props.show ? '' : undefined}
        style={{ left, top }}
      >
        <div>
          {/* Tooltip Header */}
          <div className="unit-map-tooltip__header">
            <span className="unit-map-tooltip__unit">
              Unit <em>{this.props.label}</em>
            </span>
            <span className="unit-map-tooltip__status">
              <i></i> {__statusLabelMapping[this.props.status]}
            </span>
          </div>

          <div className="unit-map-tooltip__body">
            {this.props.children}
          </div>

          {/* Tooltip Footer */}
          <div className="unit-map-tooltip__footer mb-2">
            <Button onClick={this.handleReserve} disabled={!this.props.isReserveEnabled}>Reserve</Button>
            <Button onClick={this.handleCompare}>Compare</Button>
          </div>
          <div className="unit-map-tooltip__footer">
            {this.props.isSelected
              ? <Button color="cyan" onClick={this.handleDeselect}>Deselect</Button>
              : <Button color="cyan" onClick={this.handleSelect} disabled={!this.props.isSelectEnabled}>Select</Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default UnitMapTooltip;