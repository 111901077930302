/**
 * @fileoverview UnitMapPicker is a component to select a unit from a map view.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ConfirmReservationDialog from '@Components/ConfirmReservationDialog';
import ComparisonModal from '@Components/ComparisonModal';
import SVGIcon from '@Components/SVGIcon';
import UnitMapTooltip from './UnitMapTooltip';
import UnitMapButton from './UnitMapButton';


/**
 * <UnitMapPicker/> is a component to select a unit from a map view.
 */
class UnitMapPicker extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    /** The selected unit id. */
    value: PropTypes.string,

    /** The list of units to display. */
    units: PropTypes.array,

    /** The cluster map to display. */
    clusterMap: PropTypes.string.isRequired,

    /** Called when a unit is selected. */
    onSelect: PropTypes.func,
  }

  /**
   * Initial State
   */
  state = {
    comparisonModalIsOpen: false,
    compareUnitIdFirst: null,
    compareUnitIdSecond: null,
    activeUnitId: null,
    selectedUnitId: null,
    tooltip: false,
    reservationDialog: {
      _lastClosed: new Date().getTime(),
      unitId: null,
      isOpen: false,
      label: '',
    }
  }

  _activeUnitCache = {};

  handleUnitClick = (e, unitId) => {
    e.stopPropagation();

    // Get unit
    const unit = this.props.units.find(u => u.unitno === unitId);
    if (!unit) return;
    if (unit.status !== 'available' && !unit.my_reserved_unit) return;

    // Update state
    this.setState({ activeUnitId: unitId, tooltip: true });
  }

  handleSelectClick = (unitId) => {
    if (unitId !== this.props.value) {
      this.props.onSelect(unitId);
    }
  }

  handleReserveClick = (unitId) => {
    this.setState(prevState => ({
      reservationDialog: {
        ...prevState.reservationDialog,
        unitId: unitId,
        isOpen: true,
        label: unitId,
      }
    }));
  }

  handleCompareClick = (unitId) => {
    if (this.state.compareUnitIdFirst === null) {
      this.setState({ compareUnitIdFirst: unitId, tooltip: false }, () => {
        if (this.state.compareUnitIdFirst !== null & this.state.compareUnitIdSecond !== null) {
          this.launchComparisonDialog(this.state.compareUnitIdFirst, this.state.compareUnitIdSecond);
        }
      });
    } else {
      this.setState({ compareUnitIdSecond: unitId, tooltip: false }, () => {
        if (this.state.compareUnitIdFirst !== null & this.state.compareUnitIdSecond !== null) {
          this.launchComparisonDialog(this.state.compareUnitIdFirst, this.state.compareUnitIdSecond);
        }
      });
    }
  }

  handleCancelCompareClick = () => {
    this.setState({
      compareUnitIdFirst: null,
      compareUnitIdSecond: null,
      comparisonModalIsOpen: false,
    });
  }

  toggleReservationDialog = () => {
    this.setState(prevState => ({
      reservationDialog: {
        ...prevState.reservationDialog,
        _lastClosed: new Date().getTime(),
        isOpen: !prevState.reservationDialog.isOpen
      }
    }));
  }

  launchComparisonDialog = (unitId1, unitId2) => {
    this.setState({
      compareUnitIdFirst: unitId1,
      compareUnitIdSecond: unitId2,
      comparisonModalIsOpen: true,
    });
  }

  toggleComparisonDialog = () => {
    this.setState(prevState => ({
      compareUnitIdFirst: prevState.comparisonModalIsOpen ? null : prevState.compareUnitIdFirst,
      compareUnitIdSecond: prevState.comparisonModalIsOpen ? null : prevState.compareUnitIdSecond,
      comparisonModalIsOpen: !prevState.comparisonModalIsOpen
    }));
  }

  setTooltipVisibility(visible = true) {
    // Prevent tooltip from closing when the reservation dialog is closed.
    const dialogJustClosed = (new Date().getTime() - this.state.reservationDialog._lastClosed) < 100;
    const dialogIsOpen = this.state.reservationDialog.isOpen;
    if (dialogJustClosed || dialogIsOpen) return;

    this.setState({ tooltip: visible });
  }

  getActiveUnit(unitId) {
    const unit = this._activeUnitCache[unitId]
      ? this._activeUnitCache[unitId]
      : this.props.units.find(u => u.unitno === unitId);
    this._activeUnitCache[unitId];
    return unit;
  }

  _isUnitActive(unitId) {
    if (!(this.state.activeUnitId) || !(this.state.tooltip)) return false;
    return unitId === this.state.activeUnitId;
  }

  _isUnitSelected(unitId) {
    if (unitId == null) return false;
    return unitId === this.props.value;
  }

  _isSelectedForComparison(unitId) {
    return this.state.compareUnitIdFirst === unitId || this.state.compareUnitIdSecond === unitId;
  }

  /**
   * Render Function
   */
  render() {
    const activeUnit = this.getActiveUnit(this.state.activeUnitId);
    return (
      <div className="unit-map-picker">
        <div
          className="unit-map-picker__alert alert alert-info"
          active={this.state.compareUnitIdFirst ? '' : undefined}
        >
          <SVGIcon icon="info" small/>
          <p className="ml-3">
            Select second unit to compare. <button onClick={this.handleCancelCompareClick}>Cancel</button>
          </p>
        </div>

        {/* Unit Buttons */}
        <div className="unit-map-picker__buttons">
          {this.props.units.map(unit =>
            <UnitMapButton
              key={unit.unitno}
              position={unit.position}
              size={unit.size}
              status={this._isSelectedForComparison(unit.unitno) ? 'compare' : unit.status}
              isActive={this._isUnitActive(unit.unitno)}
              isSelected={this._isUnitSelected(unit.unitno)}
              onClick={(e) => this.handleUnitClick(e, unit.unitno)}
            />
          )}
        </div>

        {/* Tooltip */}
        {activeUnit &&
          <UnitMapTooltip
            show={this.state.tooltip}
            unitId={activeUnit.unitno}
            label={activeUnit.unitno}
            status={activeUnit.status}
            position={activeUnit.position}
            isSelected={this._isUnitSelected(this.state.activeUnitId)}
            isReserveEnabled={activeUnit.status !== 'reserved'}
            isSelectEnabled={activeUnit.status === 'available' || activeUnit.my_reserved_unit === true}
            setVisibility={this.setTooltipVisibility.bind(this)}
            onReserve={this.handleReserveClick}
            onSelect={this.handleSelectClick}
            onCompare={this.handleCompareClick}
          >
            From IDR {Number(activeUnit.min_price).toLocaleString()}
          </UnitMapTooltip>
        }

        {/* Confirm Reservation Dialog */}
        <ConfirmReservationDialog
          isOpen={this.state.reservationDialog.isOpen}
          unitId={this.state.reservationDialog.unitId}
          label={this.state.reservationDialog.label}
          toggle={this.toggleReservationDialog}
        />

        {/* Comparison Modal */}
        <ComparisonModal
          isOpen={this.state.comparisonModalIsOpen}
          unitId1={this.state.compareUnitIdFirst}
          unitId2={this.state.compareUnitIdSecond}
          onToggle={this.toggleComparisonDialog}
        />

        {/* Base Image */}
        <img
          className="unit-map-picker__base-image"
          src={this.props.clusterMap}
        />
      </div>
    )
  }
}

export default UnitMapPicker;