/**
 * @fileoverview A dialog to compare two different units.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import SVGIcon from '@Components/SVGIcon';
import * as PricechartService from '@Services/PricechartService';
import * as Utils from '@Lib/index';
import EmailRequestDialog from './EmailRequestDialog';

/**
 * Map redux store to props.
 */
const mapStateToProps = (state, ownProps) => {
  const unit1 = state.units.units.find(u => u.unitno === ownProps.unitId1);
  const unit2 = state.units.units.find(u => u.unitno === ownProps.unitId2);
  return { unit1, unit2 };
};

/**
 * `<ComparisonModal/>` is a modal to compare two different units.
 */
class ComparisonModal extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    isOpen: PropTypes.bool,
    unitId1: PropTypes.string,
    unitId2: PropTypes.string,
    onToggle: PropTypes.func,
  }

  /**
   * Default Props
   */
  static defaultProps = {
    isOpen: false,
  }

  /**
   * Component State
   */
  state = {
    isLoading: false,
    isEmailRequestDialogOpen: false,
  }

  /**
   * Toggle ownself's modal.
   */
  toggle() {
    if (this.props.onToggle) {
      this.props.onToggle();
    }
  }

  /**
   * @see https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
   */
  round(value) {
    if (typeof value === 'string') {
      value = parseInt(value);
    }
    return +(Math.round(value + "e+2") + "e-2");
  }

  /**
   * Format price string with grouped thousands (ie. 1000 --> 1,000)
   */
  formatPriceString(num) {
    return Number(num).toLocaleString();
  }

  /**
   * Toggles the email request dialog.
   */
  toggleEmailRequestDialog() {
    this.setState(prevState => ({
      isEmailRequestDialogOpen: !prevState.isEmailRequestDialogOpen
    }));
  }

  /**
   * Emails the unit(s) information via the API.
   */
  emailUnitInformation(email) {
    const unitId1 = this.props.unit1.unitno;
    const unitId2 = this.props.unit2.unitno;
    this.setState({ isLoading: true });

    PricechartService.emailUnitInformation(email, [unitId1, unitId2])
      .then(() => {
        this.setState({ isLoading: false, isEmailRequestDialogOpen: false });
        window.alert("Email successfully sent!");
      })
      .catch(err => Utils.throwComponentDidCatch(this, err));
  }

  render() {
    return (
      <div>
        <Modal
          className="comparison-modal"
          isOpen={this.props.isOpen}
          toggle={this.toggle.bind(this)}
          size="lg"
          centered
        >
          <ModalHeader>
            Compare Units
          </ModalHeader>
          <ModalBody>
            {this.props.unitId1 && this.props.unitId2 &&
              <div className="comparison-modal-table">
                {/* Title Row */}
                <ComparisonModalRow
                  first={() => (
                    <h2>
                      <small className="font-weight-normal">Unit</small><br/>
                      {this.props.unit1.unitno}
                    </h2>
                  )}
                  second={() => (
                    <h2>
                      <small className="font-weight-normal">Unit</small><br/>
                      {this.props.unit2.unitno}
                    </h2>
                  )}
                />

                {/* Building Area */}
                <ComparisonModalRow
                  header={() => <span>Building Area</span>}
                  first={() => <span>{this.round(this.props.unit1.build_up_sm)} sqm</span>}
                  second={() => <span>{this.round(this.props.unit2.build_up_sm)} sqm</span>}
                />

                {/* Land Area */}
                <ComparisonModalRow
                  header={() => <span>Land Area</span>}
                  first={() => <span>{this.round(this.props.unit1.land_area_sm)} sqm</span>}
                  second={() => <span>{this.round(this.props.unit2.land_area_sm)} sqm</span>}
                />

                {/* Bedrooms */}
                <ComparisonModalRow
                  header={() => <span>Bedrooms</span>}
                  first={() => <span>{this.props.unit1.unit_rooms} bedrooms</span>}
                  second={() => <span>{this.props.unit2.unit_rooms} bedrooms</span>}
                />

                {/* Bathrooms */}
                <ComparisonModalRow
                  header={() => <span>Bathrooms</span>}
                  first={() => <span>{this.props.unit1.unit_toilets} bathrooms</span>}
                  second={() => <span>{this.props.unit2.unit_toilets} bathrooms</span>}
                />

                {/* Road */}
                <ComparisonModalRow
                  header={() => <span>Road</span>}
                  first={() => <span>{this.props.unit1.level}</span>}
                  second={() => <span>{this.props.unit2.level}</span>}
                />

                {/* Price */}
                <ComparisonModalRow
                  header={() => <span>From Price</span>}
                  first={() => <span>From IDR {this.formatPriceString(this.props.unit1.min_price)}</span>}
                  second={() => <span>From IDR {this.formatPriceString(this.props.unit2.min_price)}</span>}
                />

              </div>
            }

            {/* Floating Email Button */}
            <button className="comparison-modal-email-btn" onClick={this.toggleEmailRequestDialog.bind(this)}>
              <SVGIcon icon="email"/>
            </button>
          </ModalBody>
        </Modal>

        {/* Email Request Dialog */}
        <EmailRequestDialog
          isOpen={this.state.isEmailRequestDialogOpen}
          isLoading={this.state.isLoading}
          onSubmit={email => this.emailUnitInformation(email)}
          onToggle={this.toggleEmailRequestDialog.bind(this)}
        />
      </div>
    )
  }
}

function ComparisonModalRow(props) {
  return (
    <div className="comparison-modal-table__row">
      <div className="comparison-modal-table__header">
        {props.header ? props.header() : null}
      </div>
      <div className="comparison-modal-table__content">
        {props.first ? props.first() : null}
      </div>
      <div className="comparison-modal-table__content">
        {props.second ? props.second() : null}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(ComparisonModal);
