/**
 * @fileoverview A component to display form error messages. To be used with
 * Formik.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Prop Types
 */
const propTypes = {
  /** The name of this input and its data key. */
  name: PropTypes.string.isRequired,

  /** A Formik error object. */
  errors: PropTypes.object.isRequired,

  /** A Formik touched object. */
  touched: PropTypes.object.isRequired,
}

/**
 * <ErrorLabel/> is a component to display form error messages.
 */
function ErrorLabel(props) {
  const hasErrors = (props.touched[props.name]) && (props.errors[props.name]);
  return hasErrors
    ? <span className="form-error">{props.errors[props.name]}</span>
    : null;
}

ErrorLabel.propTypes = propTypes;

export default ErrorLabel;
