/**
 * @fileoverview A button component that wraps reactstrap's `<Button/>`
 * component with more functionalities.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button as ReactstrapButton } from 'reactstrap';
import LoaderIcon from '@Components/LoaderIcon';


/**
 * Prop Types
 */
const propTypes = {
  /** Display this button as loading. */
  loading: PropTypes.bool,

  /** Set predefined width. */
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg'])
}


/**
 * Default Props
 */
const defaultProps = {
  loading: false,
  width: null,
}


/**
 * <Button/> is a button component that wraps Reactstrap's `<Button/>`component
 * with more functionalities.
 */
function Button(props) {
  const { loading, width, children, ...otherProps } = props;
  return (
    <ReactstrapButton {...otherProps} width={width ? width : undefined}>
      {
        loading
          ? <LoaderIcon size="sm"/>
          : children
      }
    </ReactstrapButton>
  )
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;