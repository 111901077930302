/**
 * @fileoverview A component used to show hierarchy and progres between content.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '@Components/SVGIcon';

/**
 * Prop Types
 */
const propTypes = {
  /** The index of the current active breadcrumb item. */
  activeIndex: PropTypes.number.isRequired,

  /** The breadcrumb items. */
  items: PropTypes.arrayOf(PropTypes.shape({
    valid: PropTypes.bool,
    title: PropTypes.string
  })),
}

/**
 * <Breadcrumb/> is a component used to show hierarchy between content.
 */
function Breadcrumb(props) {
  return (
    <ul className="lavon-breadcrumb">
      {props.items.map((item, index) => (
        <li
          className="lavon-breadcrumb__item"
          key={item.title}
          active={props.activeIndex === index ? '' : undefined}
          valid={item.valid ? '' : undefined}
        >
          {/* Counter */}
          <span className="lavon-breadcrumb__counter">
            {
              item.valid
                ? <SVGIcon icon="check" small/>
                : <span>{index + 1}</span>
            }
          </span>

          {/* Title */}
          <span className="lavon-breadcrumb__title">
            {item.title}
          </span>

          {/* Chevron */}
          <SVGIcon icon="breadcrumb-chevron" className="lavon-breadcrumb__chevron"/>
        </li>
      ))}
    </ul>
  )
}


Breadcrumb.propTypes = propTypes;

export default Breadcrumb;
