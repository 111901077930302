/**
 * @fileoverview Defines a screen to indicate a successful request.
 */
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import Button from '@Components/Button';
import SVGIcon from '@Components/SVGIcon';

/**
 * Map redux state to props.
 */
const mapStateToProps = (state, ownProps) => ({
  booking: state.bookings.bookings[ownProps.bookingId]
});

/**
 * `<SuccessScreen/> is a screen to indicate a successful request.
 */
class SuccessScreen extends Component {

  handleBackButton = () => {
    this.props.history.push('/manage-booking');
  }

  render() {
    return (
      <div className="create-booking">
        <Container>
          {this.props.booking &&
            <div className="success-content">
              <div className="success-content__check">
                <SVGIcon icon="check"/>
              </div>
              <h1 className="success-content__title">Unit {this.props.booking.unitNo} is now sold!</h1>
            </div>
          }
        </Container>
      </div>
    )
  }
}

export default connect(mapStateToProps)(SuccessScreen);
