/**
 * @fileoverview Gets a list of the current user's booking.
 */
import axios from 'axios';
import parse from 'date-fns/parse'
import { ROOT_URL } from './constants';

/**
 * Define the API endpoint.
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostsaleshistorytransactionapi.php`;

/**
 * Get list of the current user's bookings from the API.
 */
export function getBookings() {
  return new Promise((resolve, reject) => {
    const params = {
      'filterbyaction': 'Booked',
    }

    axios.get(API_ENDPOINT, { params: params }).then((response) => {
      if (response.data.error != '') {
        reject(new Error(response.data.error));
        return;
      }
      const bookings = _transform(response.data.sales_transactions);
      resolve(bookings);
    });
  })
}

/**
 * Transform and clean up incoming data from the server.
 */
function _transform(bookings) {
  return bookings.map(booking => ({
    bookingId: booking.book_id,
    buyerName: booking.buyer_name,
    unitNo: booking.unit_no,
    unitId: booking.unitid,
    status: booking.unit_status.toLowerCase(),
    actionId: booking.actionid,
    createdAt: parse(booking.createdat),
    salesId: booking.salesid,
    cluster: booking.block,
  }));
}
