/**
 * @fileoverview Defines a screen to indicate a successful request.
 */
import React, { Component } from 'react';
import { Container } from 'reactstrap';

import SVGIcon from '@Components/SVGIcon';


/**
 * `<SuccessScreen/> is a screen to indicate a successful request.
 */
class SuccessScreen extends Component {
  render() {
    return (
      <div className="create-booking">
        <Container>
          <div className="success-content">
            <div className="success-content__check">
              <SVGIcon icon="check"/>
            </div>
            <h1 className="success-content__title">Your booking is successful</h1>
          </div>
        </Container>
      </div>
    )
  }
}

export default SuccessScreen;
