/**
 * @fileoverview Defines a higher-order function to wrap a component with
 * an <ErrorBoundary/> component.
 */
import React from "react";
import ErrorBoundary from "./ErrorBoundary";

/**
 * Wrap a component with an <ErrorBoundary/> to display a fallback component in
 * the event of errors within its child component tree.
 */
export default function withErrorBoundary(Component, boundaryProps = {}) {
  return (props) => (
    <ErrorBoundary {...boundaryProps}>
      <Component {...props}/>
    </ErrorBoundary>
  )
}
