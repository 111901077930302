/**
 * @fileoverview A text input component that wraps a <Cleave/> component with
 * Formik's <Field/>
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import { Field as FormikField } from 'formik';


/**
 * <CleaveField/> is a Formik-aware <Cleave/> component. Cleave is an utility to
 * create formatted text inputs.
 */
function CleaveField(props) {
  return <FormikField component={InnerComponent} {...props}/>
}


/**
 * Define the inner component to be wrapped with a FormikField
 */
class InnerComponent extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    useRaw: PropTypes.bool,
  }

  /**
   * Default Props
   */
  static defaultProps ={
    useRaw: false,
  }

  handleChange = (e) => {
    e.target.value = this.props.useRaw
      ? e.target.rawValue
      : e.target.value;
    this.props.field.onChange(e);
  }

  render() {
    const { placeholder, options } = this.props;
    return (
      <Cleave
        className="form-control"
        name={this.props.field.name}
        value={this.props.field.value}
        placeholder={placeholder}
        options={options}
        invalid={
          this.props.form.errors[this.props.field.name] && this.props.form.touched[this.props.field.name]
            ? ''
            : undefined
        }
        onChange={this.handleChange}
        onBlur={this.props.field.onBlur}
      />
    )
  }
}

export default CleaveField;
