/**
 * @fileoverview A screen to confirm a unit booking.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import * as PricechartService from '@Services/PricechartService';
import * as Utils from '@Lib/index';
import Button from '@Components/Button';
import DialogFooter from '@Components/DialogFooter';


/**
 * Map redux store to props.
 */
const mapStateToProps = (state, ownProps) => {
  // Get unit from store
  const unit = state.units.units.find(u => u.unitno === ownProps.value.unit.unitId);

  // Get payment plan from store
  let paymentPlan = null;
  if (ownProps.value.paymentPlan) {
    const unitPaymentPlans = state.units.paymentPlans.find(p => p.unitno === ownProps.value.unit.unitId);
    paymentPlan = unitPaymentPlans.plans.find(p => p.id === ownProps.value.paymentPlan);
  }

  // Get country
  const country = state.meta.countries.find(c => c.id === ownProps.value.details.country);

  return {
    cluster: state.units.cluster,
    computed: {
      unit: unit,
      country: country,
      paymentPlan: paymentPlan,
    }
  }
}


/**
 * Cluster to Details Mapping
 */
const _unitTypeMapping = {
  'viridia': {
    type: 'Type 6x9',
    cluster: 'Viridia',
    section: 'Park Home',
    series: 'F Series'
  },
  'margonite': {
    type: 'Type 8x9',
    cluster: 'Margonite',
    section: 'Park Home',
    series: 'H Series'
  },
  'margonite': {
    type: 'Type 8x9',
    cluster: 'Morganite',
    section: 'Park Home',
    series: 'H Series'
  },
  'montana': {
    type: 'Type 7x9',
    cluster: 'Montana',
    section: 'Park Home',
    series: 'G Series'
  },
  'scarlet': {
    type: 'Type 8x9',
    cluster: 'Scarlet',
    section: 'Park Home',
    series: 'H Series',
  },
  'citrine': {
    type: 'Type 7x11',
    cluster: 'Citrine',
    section: 'Park Home',
    series: 'B Grande Series',
  },
  'prismatiq': {
    type: 'Type 7x11',
    cluster: 'Prismatiq',
    section: 'Park Home',
    series: 'Commercial',
  }
}


/**
 * `<ConfirmationScreen/>` is a screen to confirm the booking details.
 */
class ConfirmationScreen extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    values: PropTypes.object,
  }
  
  /**
   * Initial State
   */
  state = {
    isSubmitting: false,
  }

  prev() {
    this.props.setActivePage('4');
  }

  handleSubmit() {
    const data = this.props.value;
    const computed = this.props.computed;

    this.setState({ isSubmitting: true });

    this
      ._uploadFiles([data.details.identityDoc, data.bookingFee.receipt])
      .then(filePaths => {
        data.details.identityDocUrl = filePaths.identityDoc;
        data.bookingFee.receiptUrl = filePaths.receipt;
        data.computed = computed;
        return data;
      })
      .then(_data => PricechartService.createBooking(_data))
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.setActivePage('success');
        this.props.setPageValidity(true);
      })
      .catch(err => Utils.throwComponentDidCatch(this, new Error(err)))
  }

  _uploadFiles(objectUrls) {
    return new Promise((resolve, reject) => {
      Promise
        .all(objectUrls.map(url => PricechartService.uploadObjectUrl(url)))
        .then(values => ({ identityDoc: values[0], receipt: values[1] }))
        .then(files => resolve(files));
    });
  }

  /**
   * Render Function
   */
  render() {
    const values = this.props.value;
    const computed = this.props.computed;
    const unitType = _unitTypeMapping[this.props.cluster.toLowerCase()];

    return (
      <div className="create-booking">
        <Container className="tight">
          
          {/* You are booking */}
          <ConfirmationItem title="You are booking">
            {/* Unit Details */}
            <div className="confirmation-unit">
              {/* <img src="https://unsplash.it/72/72" className="confirmation-unit__icon"/> */}
              <div className="confirmation-unit__body">
                <span>
                  {unitType.section}
                  &nbsp;|&nbsp;
                  {unitType.cluster}
                  &nbsp;|&nbsp;
                  {unitType.type}
                </span>
                <h4>{unitType.series}</h4>
              </div>
            </div>
          </ConfirmationItem>

          {/* Your unit selection */}
          {computed.unit &&
            <ConfirmationItem title="Your unit selection">
              <ul className="confirmation-details">
                <li>
                  <span className="confirmation-details__title">Unit Number</span>
                  <div className="confirmation-details__value">{computed.unit.unitno}</div>
                </li>
                <li>
                  <span className="confirmation-details__title">Road</span>
                  <div className="confirmation-details__value">{computed.unit.level}</div>
                </li>
              </ul>
            </ConfirmationItem>
          }

          {/* Your details */}
          <ConfirmationItem title="Your details">
            <ul className="confirmation-details">
              <li>
                <span className="confirmation-details__title">Name</span>
                <div className="confirmation-details__value">
                  {`${values.details.firstName} ${values.details.lastName}`}
                </div>
              </li>
              <li>
                <span className="confirmation-details__title">Mobile Number</span>
                <div className="confirmation-details__value">{values.details.mobileNo}</div>
              </li>
              <li>
                <span className="confirmation-details__title">Email Address</span>
                <div className="confirmation-details__value">{values.details.emailAddress}</div>
              </li>
              {computed.country &&
                <li>
                  <span className="confirmation-details__title">Nationality</span>
                  <div className="confirmation-details__value">{computed.country.name}</div>
                </li>
              }
              <li>
                <span className="confirmation-details__title">State</span>
                <div className="confirmation-details__value">{values.details.state || 'N/A'}</div>
              </li>
              <li>
                <span className="confirmation-details__title">City</span>
                <div className="confirmation-details__value">{values.details.state || 'N/A'}</div>
              </li>
              <li>
                <span className="confirmation-details__title">Address</span>
                <div className="confirmation-details__value">
                {values.details.address}
                </div>
              </li>
              <li>
                <span className="confirmation-details__title">Postcode</span>
                <div className="confirmation-details__value">{values.details.postcode}</div>
              </li>
              <li>
                <span className="confirmation-details__title">Tax Number</span>
                <div className="confirmation-details__value">{values.details.taxNo}</div>
              </li>
              <li>
                <span className="confirmation-details__title">KTP/Passport Number</span>
                <div className="confirmation-details__value">{values.details.identityNo}</div>
              </li>
              <li>
                <span className="confirmation-details__title">Identity Document</span>
                <div className="confirmation-details__image">
                  <img src={values.details.identityDoc}/>
                </div>
              </li>
            </ul>
          </ConfirmationItem>

          {/* Payment plan */}
          {computed.paymentPlan &&
            <ConfirmationItem title="Your payment plan">
              <ul className="confirmation-details">
                <li>
                  <span className="confirmation-details__title">Payment Plan</span>
                  <div className="confirmation-details__value">{computed.paymentPlan.name}</div>
                </li>
                <li>
                  <span className="confirmation-details__title">Payment Period</span>
                  <div className="confirmation-details__value">{computed.paymentPlan.month} month(s)</div>
                </li>
                <li>
                  <span className="confirmation-details__title">Total Price</span>
                  <div className="confirmation-details__value">IDR {computed.paymentPlan.price}</div>
                </li>
              </ul>
            </ConfirmationItem>
          }

          {/* Booking Fee */}
          <ConfirmationItem title="Booking fee">
            <ul className="confirmation-details">
              <li>
                <span className="confirmation-details__title">Amount</span>
                <div className="confirmation-details__value">
                  IDR {values.bookingFee.amount}
                </div>
              </li>
              <li>
                <span className="confirmation-details__title">Payment Method</span>
                <div className="confirmation-details__value">{values.bookingFee.method}</div>
              </li>
              <li>
                <span className="confirmation-details__title">Proof of Payment</span>
                <div className="confirmation-details__image">
                  <img src={values.bookingFee.receipt}/>
                </div>
              </li>
            </ul>
          </ConfirmationItem>

          {/* Footer */}
          <DialogFooter
            nextFn={this.handleSubmit.bind(this)}
            prevFn={this.prev.bind(this)}
            prevRender={(props) => (
              <Button
                color="secondary"
                className="btn-160 footer__back-btn"
                onClick={() => props.prevFn()}
                disabled={this.state.isSubmitting}
              >
                {props.prevLabel}
              </Button>
            )}
            nextRender={(props) => (
              <Button
                color="next"
                className="btn-160 footer__next-btn"
                onClick={() => props.nextFn()}
                loading={this.state.isSubmitting}
                disabled={this.state.isSubmitting}
              >
                Confirm
              </Button>
            )}
          />
        </Container>
      </div>
    )
  }
}


function ConfirmationItem(props) {
  return (
    <div className="confirmation-screen-item">
      <span className="confirmation-screen-item__title">{props.title}</span>
      <div className="confirmation-screen-item__content">
        {props.children}
      </div>
    </div>
  )
}

ConfirmationItem.propTypes = {
  title: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(ConfirmationScreen);
