/**
 * @fileoverview A screen to enter booking details.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { Container } from 'reactstrap';

import * as Utils from '@Lib/index';
import Button from '@Components/Button';
import SVGIcon from '@Components/SVGIcon';
import DialogFooter from '@Components/DialogFooter';

import DetailsForm from './DetailsForm';
import SelectProspectDialog from './SelectProspectDialog';


/**
 * Validation Schema
 */
const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('This field is required.'),
  lastName: yup
    .string()
    .required('This field is required.'),
  emailAddress: yup
    .string()
    .email('Please enter a valid email address.')
    .required('This field is required.'),
  mobileNo: yup
    .string()
    .required('This field is required.'),
  country: yup
    .string()
    .required('This field is required.'),
  address: yup
    .string()
    .required('This field is required.'),
  postcode: yup
    .string()
    .required('This field is required.'),
  taxNo: yup
    .string()
    .required('This field is required.'),
  identityNo: yup
    .string()
    .required('This field is required'),
  identityDoc: yup
    .string()
    .required('This field is required'),
})


/**
 * Map redux store to props.
 */
const mapStateToProps = (state, ownProps) => ({
  countries: state.meta.countries,
  prospects: state.units.prospectIds.map(id => state.units.prospects[id])
});


/**
 * EnterDetailsScreen is a screen component to enter booking details.
 */
class EnterDetailsScreen extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    /** The form values. */
    value: PropTypes.object.isRequired,

    /** Call this function to navigate between pages. */
    setActivePage: PropTypes.func.isRequired,

    /** Call this function to set a page's validity. */
    setPageValidity: PropTypes.func.isRequired,
    
    /** Call this function to update the bookingContext store. */
    onSubmit: PropTypes.func.isRequired,
  }

  /**
   * Initial State
   */
  state = {
    isProspectDialogOpen: false,
  }

  prev() {
    this.props.setActivePage('1');
    this.props.onSubmit(this.props.values);

    // Manually validate values
    validationSchema.isValid(this.props.values).then((isValid) => {
      this.props.setPageValidity(isValid);
    });
  }

  next() {
    this.props.submitForm();

    // Manually validate values
    validationSchema.isValid(this.props.values).then((isValid) => {
      if (!isValid) {
        Utils.triggerCssClass('.footer__next-btn', ['btn-danger', 'shake'], 820);
      }
    });
  }

  toggleProspectDialog() {
    this.setState(prevState => ({
      isProspectDialogOpen: !prevState.isProspectDialogOpen
    }));
  }

  handleProspectSelect(prospectId) {
    this.toggleProspectDialog();
    const prospect = this.props.prospects.find(p => p.id === prospectId);

    this.props.setFieldValue('firstName', prospect.firstName);
    this.props.setFieldValue('lastName', prospect.lastName);
    this.props.setFieldValue('emailAddress', prospect.email);
    this.props.setFieldValue('mobileNo', prospect.contactNo);
  }

  render() {
    return (
      <div className="create-booking">
        <Container className="tight">

          <div className="enter-details-actions">
            <Button color="secondary" width="lg" className="ml-auto" onClick={this.toggleProspectDialog.bind(this)}>
              <SVGIcon icon="person-face" color="white" small/>&ensp;Select Prospect ({this.props.prospects.length})
            </Button>
          </div>

          {/* Form */}
          <div className="transitionable">
            <DetailsForm
              {...this.props}
            />
          </div>

          {/* Select Prospect Dialog */}
          <SelectProspectDialog
            open={this.state.isProspectDialogOpen}
            prospects={this.props.prospects}
            toggle={this.toggleProspectDialog.bind(this)}
            onSelect={this.handleProspectSelect.bind(this)}
          />

          {/* Footer */}
          <DialogFooter
            nextFn={this.next.bind(this)}
            prevFn={this.prev.bind(this)}
          />

        </Container>
      </div>
    )
  }
}


/**
 * Formik wrapped HOC of EnterDetailsScreen.
 */
const FormikEnterDetailsScreen = withFormik({

  /** Map initial props to values */
  mapPropsToValues: (props) => {
    return props.value
  },

  /** Handle form submission */
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(false);
    props.onSubmit(values);
    props.setActivePage('3');
    props.setPageValidity(true);
  },

  /**
   * Specify the form validation schema using a Yup schema.
   * @reference https://github.com/jquense/yup
   */
  validationSchema: validationSchema,

})(EnterDetailsScreen);

export default connect(mapStateToProps)(FormikEnterDetailsScreen);