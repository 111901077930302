import axios from 'axios';

import { ROOT_URL } from './constants';

/**
 * Define the API endpoint.
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostunitapi.php`;


/**
 * Represents a single unit.
 * @typedef Unit
 * @property {string} unit_no The unit no of this unit.
 * @property {string} status The status of this unit.
 * @property {string} level The street name of this unit.
 * @property {string} block The clusterId of this unit.
 * @property {number} min_price The lowest price of this unit.
 * @property {boolean} my_reserved_unit This unit is reserved by the user.
 * @property {boolean} bookqueexist Whether this unit already has a queued
 *  booking.
 * @property {string} unittype
 */


/**
 * Get list of units from the API.
 * @param {string} clusterId
 * @returns {Promise<Unit[]>}
 */
export function getUnits(clusterId) {
  return new Promise((resolve,reject) => {
    const params = {
      'filter_by_block': clusterId,
    };

    axios.get(API_ENDPOINT, { params: params }).then((response) => {
      if (response.data.error != '') {
        reject(new Error(`Unable to resolve unit details '${response.data.error}'`));
        return;
      }

      const units = response.data.units
        .map(unit => _transformData(unit))
        .map(unit => _mergeReserved(unit, response.data.reserve_units));

      resolve(units);
    });
  });
}


/**
 * Transform the API response data for consumption.
 * @param {Object} unit 
 */
function _transformData(unit) {
  unit.status = unit.status.toLowerCase(); // Transform status to lowercase
  return unit;
}


/**
 * Merge units with reservation data.
 * @param {Unit} unit
 * @param {object} reservedUnits List of reserved units
 * @returns {Unit}
 */
function _mergeReserved(unit, reservedUnits) {
  const reservation = reservedUnits.find(u => unit.unitno === u.unitno);
  if (reservation) {
    unit.reserved_at = reservation.activityat;
    unit.my_reserved_unit = true;
  } else {
    unit.reserved_at = null;
    unit.my_reserved_unit = false;
  }
  return unit;
}
