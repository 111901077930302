/**
 * @fileoverview Define constants for the PricechartService
 */

/**
 * Define the API root URL.
 */
export const ROOT_URL = IS_PRODUCTION
  ? 'https://pricechart2.lavon.co.id'
  : 'https://pricechart2-training.lavon.co.id';
