/**
 * @fileoverview A component to render a preset list of SVG Icons.
 */
import React from 'react';
import PropTypes from 'prop-types';


/**
 * Prop Types
 */
const propTypes = {
  /** Name of the icon to use. */
  icon: PropTypes.string.isRequired,

  /** Sets a smaller sized icon. */
  small: PropTypes.bool,

  /** Sets the icon color */
  color: PropTypes.oneOf(['white', 'black']),
}


/**
 * <SVGIcon/> is a component to render from a preset list of SVG icons.
 */
function SVGIcon(props) {
  const { icon, small, color, ...otherProps } = props;
  const cn = ['icon', color ? `icon-${color}` : null].filter(s => s !== null).join(' ');
  return (
    <svg
      className={cn}
      role="img"
      small={small ? '' : undefined}
      {...otherProps}
    >
      <use href={`svg/sprites.svg#${icon}`} xlinkHref={`/svg/sprites.svg#${icon}`}></use>
    </svg>
  )
}

SVGIcon.propTypes = propTypes;

export default SVGIcon;
