/**
 * @fileoverview A component to display the unit availability list's header.
 */
import React from 'react';

function UnitHeader(props) {
  return (
    <div className="unit-availability-header">
      {/* Status */}
      <div className="unit-availability-header__status">
        Status
      </div>

      {/* Label */}
      <div className="unit-availability-header__label">
        Unit
      </div>

      {/* Price */}
      <div className="unit-availability-header__price">
        Price
      </div>

      {/* Reservation Expiry */}
      <div className="unit-availability-header__expiry">
        Reservation Expiry
      </div>
    </div>
  )
}

export default UnitHeader;