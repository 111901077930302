/**
 * @fileoverview Implements a DialogFooter component for use of a persistent
 * footer based dialog flow.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Button from '@Components/Button';

/**
 * Prop Types
 */
const propTypes = {
  /** Toggle the disabled state of the entire footer. */
  disabled: PropTypes.bool,

  /** Toggle visibility of the next button. */
  next: PropTypes.bool,

  /** Toggle visibility of the prev button. */
  prev: PropTypes.bool,

  /** Called when the next button is clicked. */
  nextFn: PropTypes.func,

  /** Called when the prev button is clicked. */
  prevFn: PropTypes.func,

  /** The label of the next button */
  nextLabel: PropTypes.string,

  /** The label of the prev button */
  prevLabel: PropTypes.string,

  /** The next button render function */
  nextRender: PropTypes.func,

  /** The prev button render function */
  prevRender: PropTypes.func,

  /** Function to render elements on the right side of the footer. */
  renderRight: PropTypes.func,

  /** Function to render elements on the left side of the footer. */
  renderLeft: PropTypes.func,
}


/**
 * Default Props
 */
const defaultProps = {
  next: true,
  prev: true,
  nextFn: () => {},
  prevFn: () => {},
  nextLabel: 'Next',
  prevLabel: 'Back',
  nextRender: (props) => {
    return (
      <Button color="next" className="btn-160 footer__next-btn" onClick={() => props.nextFn()}>
        {props.nextLabel}
      </Button>
    )
  },
  prevRender: (props) => {
    return (
      <Button color="secondary" className="btn-160 footer__back-btn" onClick={() => props.prevFn()}>
        {props.prevLabel}
      </Button>
    )
  },
  renderRight: () => null,
  renderLeft: () => null,
}

/**
 * <DialogFooter/> is a persistent footer for dialog-based flows.
 */
function DialogFooter(props) {
  return ReactDOM.createPortal(
    (
      <div className="footer" disable={props.disabled ? '' : undefined}>
        <Container fluid>
          <Row className="justify-content-between">

            {/* Footer Left */}
            <Col>
              {props.renderLeft(props)}
              {props.prev && props.prevRender(props)}
            </Col>

            {/* Footer Right */}
            <Col className="text-right">
              {props.renderRight(props)}
              {props.next && props.nextRender(props)}
            </Col>

          </Row>
        </Container>
      </div>
    ), document.getElementById('footer-root')
  )
}


DialogFooter.propTypes = propTypes;
DialogFooter.defaultProps = defaultProps;


export default DialogFooter;
