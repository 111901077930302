/**
 * @fileoverview A component to overlay a screen with a loading indicator.
 */
import React from 'react';
import PropTypes from 'prop-types';
import LoaderIcon from '@Components/LoaderIcon';

/**
 * Prop Types
 */
const propTypes = {
  show: PropTypes.bool,
  label: PropTypes.string,
  zIndex: PropTypes.number,
  fill: PropTypes.bool,
}


/**
 * Default Props
 */
const defaultProps = {
  show: false,
  label: 'Loading',
  zIndex: null,
  fill: true,
}


/**
 * ScreenLoadingIndicator is a component to overlay a screen with a loading
 * indicator.
 */
function ScreenLoadingIndicator(props) {
  const cn = ['screen-loading-indicator', props.fill ? 'is-fill-screen' : null].filter(s => s !== null).join(' ');
  return (
    <div
      className={cn}
      show={props.show ? '' : undefined}
      style={{ zIndex: props.zIndex }}
    >
      <div>
        <LoaderIcon/>
        <span className="screen-loading-indicator__text">Loading</span>
      </div>
    </div>
  )
}

ScreenLoadingIndicator.propTypes = propTypes;
ScreenLoadingIndicator.defaultProps = defaultProps;

export default ScreenLoadingIndicator;
