/**
 * @fileoverview A component to display a list of units and its availability
 * status.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import parse from 'date-fns/parse';
import _ from 'lodash';

import UnitRow from './UnitRow';
import UnitHeader from './UnitHeader';
import UnitFilter from './UnitFilter';
import ConfirmReservationDialog from '@Components/ConfirmReservationDialog';
import InView from '@Components/InView';

import * as UnitsActions from '@Stores/units/actions';
import { isEmptyChildren } from 'formik';


/**
 * Map redux store to props.
 */
const mapStateToProps = (state) => ({
  units: state.units.units,
  counters: state.units.counters,
  isLoading: state.units.loading,
  ui: state.units.ui,
});


/**
 * <UnitListPicker/> is a component to display a list of units and its
 * availability status.
 */
class UnitListPicker extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    /** The selected unit id. */
    value: PropTypes.string,

    /** The list of units to display. */
    units: PropTypes.array,

     /** The list of reserve units to display. */
    reserveUnits: PropTypes.array,

    /** Called when an unit is selected. */
    onSelect: PropTypes.func.isRequired,
  }

  /**
   * Initial State
   */
  state = {
    reservationDialog: {
      unitId: null,
      isOpen: false,
    },
    filter: 'available',
    limit: 100,
    expiryTime: {}
  }

  _intervalId = null

  componentDidMount() {
    this._intervalId = setInterval(this.refreshTime, 30000);
  }

  componentWillUnmount() {
    clearInterval(this._intervalId);
  }

  static getDerivedStateFromProps(props) {
    return {
      expiryTime: UnitListPicker._getExpiryTime(props.units)
    }
  }

  static _calculateCountdown(date) {
    const now = new Date().getTime();
    const distance = now - date.getTime();
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    return 30 - minutes;
  }

  static _getExpiryTime(units = []) {
    return units.reduce((acc, unit) => {
      if (unit.reserved_at !== null) {
        const reservationDate = parse(unit.reserved_at);
        acc[unit.unitno] = UnitListPicker._calculateCountdown(reservationDate);
      }
      return acc;
    }, {});
  }

  refreshTime = () => {
    const expiryTime = UnitListPicker._getExpiryTime(this.props.units);

    // Remove reserved status if expiry is negative
    Object.keys(expiryTime).forEach(unitno => {
      const expiry = expiryTime[unitno];
      if (expiry >= 0) {
        return;
      }

      // Update store
      const unit = this.props.units.find(u => u.unitno === unitno);
      if (unit) {
        unit.status = 'available';
        unit.my_reserved_unit = false;
        unit.reserved_at = null;
        this.props.dispatch(UnitsActions.replaceUnit(unit));
      }

      // Remove time from object
      delete expiryTime[unitno];
    });

    this.setState({ expiryTime: expiryTime });
  }

  launchReservationDialog(unitId) {
    this.setState({
      reservationDialog: { unitId: unitId, isOpen: true }
    });
  }

  toggleReservationDialog() {
    this.setState(prevState => ({
      reservationDialog: {
        ...prevState.reservationDialog,
        isOpen: !prevState.reservationDialog.isOpen
      }
    }));
  }

  handleScrollToBottom(e) {
    if (this.state.limit > 0 && this.state.limit < this.props.units.length) {
      this.setState(prevState => ({ limit: prevState.limit + 50 }));
    }
  }

  handleUnitSelect(e, unitLabel) {
    this.props.onSelect(unitLabel);
  }

  handleFilter(filter) {
    this.setState({ filter: filter, limit: 100 });
  }

  minPriceTag(num) {
    const amount = Number(num).toLocaleString();
    return `From IDR ${amount}`;
  }

  _filterUnits(status) {
    if (status === 'all') {
      return this.props.units;
    } else if (status === 'available') {
      return this.props.units.filter(u => u.status === status || u.my_reserved_unit);
    } else if (status === 'reserved') {
      return this.props.units.filter(u => u.my_reserved_unit);
    }
  }

  _getFilterOptions() {
    return [
      {
        filter: "all",
        label: `All`,
        counter: _.get(this.props.counters, "all", 0)
      },
      {
        filter: "available",
        label: `Available`,
        counter: _.get(this.props.counters, "available", 0)
      },
      {
        filter: "reserved",
        label: `You Reserved`,
        counter: _.get(this.props.counters, "my_reserved", 0)
      }
    ];
  }

  /**
   * Render Function
   */
  render() {

    const filteredUnits = this.state.limit > 0
      ? this._filterUnits(this.state.filter).slice(0, this.state.limit)
      : this._filterUnits(this.state.filter);

    return (
      <div>
        {/* Unit Availability Filter */}
        <UnitFilter
          items={this._getFilterOptions()}
          value={this.state.filter}
          onFilter={this.handleFilter.bind(this)}
        />

        {/* Unit Availability Header Row */}
        {filteredUnits.length > 0 &&
          <UnitHeader/>
        }

        {/* Unit Availability Item Rows */}
        {filteredUnits.map(unit => (
          <UnitRow
            selected={unit.unitno === this.props.value}
            key={unit.unitno}
            label={unit.unitno}
            price={this.minPriceTag(unit.min_price)}
            status={unit.status}
            expiryTime={this.state.expiryTime[unit.unitno]}
            myReservedUnit={unit.my_reserved_unit}
            onSelect={this.handleUnitSelect.bind(this)}
            onReserve={() => this.launchReservationDialog(unit.unitno)}
          />
        ))}

        <InView
          active={!this.props.isLoading}
          onEnter={this.handleScrollToBottom.bind(this)}
        />

        {/* Empty State */}
        {filteredUnits.length < 1 &&
          <div className="unit-availability-empty">
            No units available
          </div>
        }

        {/* Confirm Reservation Dialog */}
        <ConfirmReservationDialog
          isOpen={this.state.reservationDialog.isOpen}
          unitId={this.state.reservationDialog.unitId}
          toggle={this.toggleReservationDialog.bind(this)}
        />
      </div>
    )
  }

}

export default connect(mapStateToProps)(UnitListPicker);
