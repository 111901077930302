/**
 * @fileoverview A screen to select a payment plan.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as UnitActions from '@Stores/units/actions';

import * as Utils from '@Lib/index';
import ScreenLoadingIndicator from '@Components/ScreenLoadingIndicator';
import DialogFooter from '@Components/DialogFooter';
import PaymentPlanPicker from '@Components/PaymentPlanPicker';


/**
 * Map redux store to props.
 */
const mapStateToProps = (state, ownProps) => {
  const plans = state.units.paymentPlans.find(p => p.unitno === ownProps.unitId);
  return {
    plans: plans ? plans.plans : null,
    isLoading: state.meta.loading['payment_plans'] === true,
  }
};


/**
 * `<PaymentPlanScreen/>` is a component to select a payment plan.
 */
class PaymentPlanScreen extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    unitId: PropTypes.string,
    plans: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  /**
   * Default Props
   */
  static defaultProps = {
    plans: null,
    isLoading: false,
  }

  /**
   * Initial State
   */
  state = {
    paymentPlanId: null,
    error: null
  }

  componentDidMount() {
    // Load payment plan from API
    if (this.props.plans === null) {
      this.props
        .dispatch(UnitActions.getPaymentPlan(this.props.unitId))
        .catch(err => Utils.throwComponentDidCatch(this, new Error(err)));
    }
    this.setState({ paymentPlanId: this.props.value });
  }

  prev() {
    this.props.onSubmit(this.state.paymentPlanId);
    this.props.setPageValidity(this.state.paymentPlanId !== null);
    this.props.setActivePage('2');
  }

  next() {
    this.props.setPageValidity(this.state.paymentPlanId !== null);
    
    // Return if no payment plan is selected
    if (this.state.paymentPlanId === null) {
      Utils.triggerCssClass('.footer__next-btn', ['shake', 'btn-danger'], 820);
      this.setState({ error: 'Please select a payment plan.' });
      return;
    }

    this.props.onSubmit(this.state.paymentPlanId);
    this.props.setActivePage('4');
  }

  handleSelect(paymentPlanId) {
    this.setState({ paymentPlanId: paymentPlanId });
  }

  /**
   * Render Function
   */
  render() {
    return (
      <div className="create-booking">
        {/* Loading Indicator */}
        <ScreenLoadingIndicator show={this.props.isLoading}/>

        {/* Cash */}
        {this.props.plans &&
          <div className="payment-plan-type">
            <h2 className="payment-plan-type__header">Select a Payment Plan</h2>
            <PaymentPlanPicker
              value={this.state.paymentPlanId}
              plans={this.props.plans}
              onSelect={this.handleSelect.bind(this)}
            />
          </div>
        }

        {/* Footer */}
        <DialogFooter
          nextFn={this.next.bind(this)}
          prevFn={this.prev.bind(this)}
          renderRight={() => {
            return this.state.error
              ? <span className="footer__error">{this.state.error}</span>
              : null
          }}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps)(PaymentPlanScreen);
