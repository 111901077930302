/**
 * @fileoverview A dialog to create a new unit booking.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Breadcrumb from '@Components/Breadcrumb';

import ScrollCreateBookingToTop from './ScrollCreateBookingToTop';
import SelectUnitScreen from './SelectUnitScreen';
import BookingFeeScreen from './BookingFeeScreen';
import EnterDetailsScreen from './EnterDetailsScreen';
import PaymentPlanScreen from './PaymentPlanScreen';
import ConfirmationScreen from './ConfirmationScreen';
import SuccessScreen from './SuccessScreen';

/**
 * Map redux store to props.
 */
const mapStateToProps = (state, ownProps) => ({
  user: { username: state.meta.username, password: state.meta.password }
});

/**
 * `<CreateBooking/> is a route to create a new unit booking.
 */
class CreateBooking extends Component {

  /**
   * Initial State
   */
  state = {
    activeId: '1',

    /** Breadcrumb items and its status. */
    breadcrumb: [
      { pageId: '1', title: 'Select Unit',   valid: false },
      { pageId: '2', title: 'Enter Details', valid: false },
      { pageId: '3', title: 'Payment Plans', valid: false },
      { pageId: '4', title: 'Booking Fee',   valid: false },
      { pageId: '5', title: 'Confirmation',  valid: false }
    ],

    /** Contains all the booking data. */
    bookingContext: {
      unit: {
        unitId: null,
      },
      details: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        mobileNo: '',
        country: '100',
        state: '',
        city: '',
        address: '',
        postcode: '',
        taxNo: '',
        identityNo: '',
        identityDoc: '',
      },
      paymentPlan: null,
      bookingFee: {
        amount: '',
        method: 'Credit Card',
        receipt: '',
      }
    }
  }

  componentWillMount() {
    // Update activeId when URL changes
    this.props.history.listen((location) => {
      const res = /\/create-booking\/(\w+)/.exec(location.pathname);
      if (res) {
        const urlId = res[1];
        this.setState({ activeId: urlId });
      }
    });
  }

  componentDidMount() {
    // Update activeId according to the URL
    const res = /\/create-booking\/(\w+)/.exec(this.props.location.pathname);
    if (res) {
      const urlId = res[1];
      this.setState({ activeId: urlId });
    }
  }

  setActivePage(pageId) {
    // Update the location hash, which will trigger the history listener to
    // update the state of activeId.
    window.location.hash = `/create-booking/${pageId}`;
  }

  setPageValidity(pageId, valid = true) {
    this.setState((prevState) => {
      const breadcrumb = prevState.breadcrumb.map((item) => ({
        ...item, valid: item.pageId === pageId ? valid : item.valid
      }));
      return { breadcrumb: breadcrumb }
    });
  }

  handleUnitSelect(unitId, isValid) {
    this.setState((prevState) => ({
      bookingContext: {
        ...prevState.bookingContext,
        unit: { unitId: unitId, _valid: isValid }
      }
    }))
  }

  handleDetailsSubmit(values) {
    this.setState((prevState) => ({
      bookingContext: { ...prevState.bookingContext, details: values }
    }));
  }

  handlePaymentPlanSubmit(paymentPlanId) {
    this.setState((prevState) => ({
      bookingContext: { ...prevState.bookingContext, paymentPlan: paymentPlanId }
    }));
  }

  handleBookingFeeSubmit(values) {
    this.setState((prevState) => ({
      bookingContext: { ...prevState.bookingContext, bookingFee: values }
    }));
  }

  _getBreadcrumbIndex() {
    return this.state.breadcrumb.findIndex(
      item => item.pageId === this.state.activeId
    );
  }

  /**
   * Render Function
   */
  render() {
    return (
      <div>
        {/* Breadcrumb */}
        <Breadcrumb activeIndex={this._getBreadcrumbIndex()} items={this.state.breadcrumb}/>

        {/* Main Content Area */}
        <TransitionGroup>
          <CSSTransition
            key={this.props.location.pathname}
            classNames="create-booking"
            timeout={600}
          >
            <Switch location={this.props.location}>

              {/* Default Route */}
              <Route exact path="/create-booking" render={() => (
                <Redirect to="/create-booking/1"/>
              )}/>

              {/* Step 1: Select Unit */}
              <Route path="/create-booking/1" render={(props) => (
                <ScrollCreateBookingToTop {...props}>
                  <SelectUnitScreen
                    value={this.state.bookingContext.unit.unitId}
                    onSubmit={this.handleUnitSelect.bind(this)}
                    setActivePage={this.setActivePage.bind(this)}
                    setPageValidity={(valid) => this.setPageValidity('1', valid)}
                  />
                </ScrollCreateBookingToTop>
              )}/>

              {/* Step 2: Enter Details */}
              <Route path="/create-booking/2" render={(props) => (
                <ScrollCreateBookingToTop {...props}>
                  <EnterDetailsScreen
                    value={this.state.bookingContext.details}
                    onSubmit={this.handleDetailsSubmit.bind(this)}
                    setActivePage={this.setActivePage.bind(this)}
                    setPageValidity={(valid) => this.setPageValidity('2', valid)}
                  />
                </ScrollCreateBookingToTop>
              )}/>

              {/* Step 3: Payment Plan */}
              <Route path="/create-booking/3" render={(props) => (
                <ScrollCreateBookingToTop {...props}>
                  <PaymentPlanScreen
                    unitId={this.state.bookingContext.unit.unitId}
                    value={this.state.bookingContext.paymentPlan}
                    onSubmit={this.handlePaymentPlanSubmit.bind(this)}
                    setActivePage={this.setActivePage.bind(this)}
                    setPageValidity={(valid) => this.setPageValidity('3', valid)}
                  />
                </ScrollCreateBookingToTop>
              )}/>

              {/* Step 4: Booking Fee */}
              <Route path="/create-booking/4" render={(props) => (
                <ScrollCreateBookingToTop {...props}>
                  <BookingFeeScreen
                    value={this.state.bookingContext.bookingFee}
                    onSubmit={this.handleBookingFeeSubmit.bind(this)}
                    setActivePage={this.setActivePage.bind(this)}
                    setPageValidity={(valid) => this.setPageValidity('4', valid)}
                  />
                </ScrollCreateBookingToTop>
              )}/>

              {/* Step 5: Confirmation */}
              <Route path="/create-booking/5" render={(props) => (
                <ScrollCreateBookingToTop {...props}>
                  <ConfirmationScreen
                    value={this.state.bookingContext}
                    setActivePage={this.setActivePage.bind(this)}
                    setPageValidity={(valid) => this.setPageValidity('5', valid)}
                  />
                </ScrollCreateBookingToTop>
              )}/>

              {/* Step 6: Results */}
              <Route path="/create-booking/success" render={(props) => (
                <ScrollCreateBookingToTop {...props}>
                  <SuccessScreen
                    setActivePage={this.setActivePage.bind(this)}
                  />
                </ScrollCreateBookingToTop>
              )}/>

              {/* Default Route */}
              <Route render={() => <div>Not Found</div>} />

            </Switch>
          </CSSTransition>
        </TransitionGroup>

      </div>
    )
  }
}

export default connect(mapStateToProps)(CreateBooking);
