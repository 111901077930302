/**
 * @fileoverview Define a component that scrolls the '.create-booking' container
 * to the top when mounted or when the `location` prop changes.
 */

import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * <ScrollCreateBookingToTop/> is a component that scrolls the '.create-booking'
 * container to the top when mounted or when the `location` prop changes.
 */
class ScrollCreateBookingToTop extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    location: PropTypes.any,
  }

  componentDidMount() {
    this._scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._scrollToTop();
    }
  }

  _scrollToTop() {
    const container = document.querySelector('.create-booking');
    if (container) {
      container.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }

}

export default ScrollCreateBookingToTop;
