/**
 * @fileoverview A component to display a single payment plan item.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import SVGIcon from '@Components/SVGIcon';

/**
 * Prop Types
 */
const propTypes = {
  /** Show this row as selected. */
  selected: PropTypes.bool,

  /** The plan id of this payment plan. */
  id: PropTypes.string.isRequired,

  /** The terms of this payment plan. */
  terms: PropTypes.string.isRequired,

  /** The payment period of this payment plan. */
  period: PropTypes.string.isRequired,

  /** The total purchase price of this payment plan. */
  price: PropTypes.string.isRequired,
  
  /** Called when this payment plan is selected. */
  onSelect: PropTypes.func,
}


/**
 * Default Props
 */
const defaultProps = {
  selected: false,
  onSelect: () => {}
}


/**
 * <PaymentPlanRow/> is a component to display a single payment plan item.
 */
function PaymentPlanRow(props) {
  return (
    <div
      className="payment-plan-picker-row"
      active={props.selected ? '' : undefined}
      onClick={(e) => props.onSelect(e, props.id)}
    >
      {/* Status */}
      <div className="payment-plan-picker-row__col payment-plan-picker-row__status">
        {props.selected && <SVGIcon icon="check" small/>}
      </div>

      {/* Terms */}
      <div className="payment-plan-picker-row__col payment-plan-picker-row__terms">
        {props.terms}
      </div>

      {/* Period */}
      <div className="payment-plan-picker-row__col payment-plan-picker-row__period">
        {props.period}
      </div>

      {/* Price */}
      <div className="payment-plan-picker-row__col payment-plan-picker-row__price">
        <strong>IDR</strong>&ensp;{props.price}
      </div>

      {/* Actions */}
      <div className="payment-plan-picker-row__col payment-plan-picker-row__actions">
        {/* <Button
          className="payment-plan-picker-row__info"
          color="link"
          onClick={(e) => { e.stopPropagation(); console.log('inner'); }}
        >
          <SVGIcon icon="info"/>
        </Button> */}
      </div>
    </div>
  )
}

PaymentPlanRow.propTypes = propTypes;
PaymentPlanRow.defaultProps = defaultProps;

export default PaymentPlanRow;
