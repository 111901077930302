/**
 * @fileoverview Defines how the `meta` state should change in response to
 * actions sent to the store.
 */

import createReducer from '@Lib/createReducer';
import { ACTION_TYPES as ACTIONS } from './actions';


/**
 * Initial State
 */
const initialState = {
  username: null,
  password: null,
  loading: {
    countries: false,
  },
  countries: [],
}


/**
 * This function defines how the `meta` state should change in response to
 * actions sent to the store.
 */
const metaReducer = createReducer(initialState, {

  /** SET_AUTH */
  [ACTIONS.SET_AUTH]: (state, action) => {
    return { ...state, ...action.payload }
  },

  /** LOGOUT */
  [ACTIONS.LOGOUT]: (state) => {
    return { ...state, username: null, password: null }
  },

  /** SET_COUNTRY_LIST */
  [ACTIONS.SET_COUNTRY_LIST]: (state, action) => {
    return { ...state, countries: action.payload }
  },

  /** SET_LOADING */
  [ACTIONS.SET_META_LOADING]: (state, action) => {
    return {
      ...state,
      loading: {
        ...state._loading,
        [action.payload.id]: action.payload.value
      }
    }
  }

})

export default metaReducer;
