/**
 * @fileoverview This file defines how the units state should change in response
 * to actions sent to the store.
 */
import createReducer from '@Lib/createReducer';
import { ACTION_TYPES as ACTIONS } from './actions';


/**
 * Initial State
 */
const initialState = {
  ui: 'map',
  units: [],
  reserveUnits: [],
  paymentPlans: [],
  cluster: '',
  clusterData: {},
  loading: false,
  isReserveLoading: false,
  isPaymentPlanLoading: false,
  prospects: {},
  prospectIds: [],
  counters: {},
}


/**
 * This function defines how the units state should change in response to
 * actions sent to the store.
 */
const unitsReducer = createReducer(initialState, {

  /** SET_UNITS */
  [ACTIONS.SET_UNITS]: (state, action) => {
    return { ...state, units: action.payload }
  },

  /** REPLACE_UNIT */
  [ACTIONS.REPLACE_UNIT]: (state, action) => {
    return {
      ...state,
      units: state.units.map((unit) => {
        return action.payload.unitno === unit.unitno
          ? action.payload
          : unit;
      })
    }
  },

  /** SET_CLUSTER_DATA */
  [ACTIONS.SET_CLUSTER_DATA]: (state, action) => {
    return {
      ...state,
      clusterData: {
        ...state.clusterData,
        [action.payload.clusterId]: action.payload.data,
      }
    }
  },

  /** SET_CLUSTER */
  [ACTIONS.SET_CLUSTER]: (state, action) => {
    return { ...state, cluster: action.payload }
  },

  /** STORE_PAYMENT_PLAN */
  [ACTIONS.STORE_PAYMENT_PLAN]: (state, action) => {
    const plans = state.paymentPlans.slice();
    const existingIdx = plans.findIndex(
      p => p.unitno === action.payload.unitno
    );

    if (existingIdx > -1) {
      plans[existingIdx] = action.payload;
    } else {
      plans.push(action.payload);
    }

    return { ...state, paymentPlans: plans };
  },

  /** SET_RESERVE_UNITS */
  [ACTIONS.SET_RESERVE_UNITS]: (state, action) => {
    return { ...state, reserveUnits: action.payload }
  },

  /** SET_UNITS_UI */
  [ACTIONS.SET_UNITS_UI]: (state, action) => {
    return { ...state, ui: action.payload }
  },

  /** SET_LOADING */
  [ACTIONS.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.payload }
  },

  /** SET_RESERVE_LOADING */
  [ACTIONS.SET_RESERVE_LOADING]: (state, action) => {
    return { ...state, isReserveLoading: action.payload }
  },

  /** SET_PAYMENT_PLAN_LOADING */
  [ACTIONS.SET_PAYMENT_PLAN_LOADING]: (state, action) => {
    return { ...state, isPaymentPlanLoading: action.payload }
  },

  /** SET_PROSPECTS */
  [ACTIONS.SET_PROSPECTS]: (state, action) => {
    return {
      ...state,

      // Flatten list of prospects to a key value structure
      prospects: action.payload.reduce((acc, prospect) => {
        acc[prospect.id] = prospect;
        return acc;
      }, state.prospects),

      // Flatten to list of prospect IDs
      prospectIds: action.payload.reduce((acc, prospect) => {
        if (acc.indexOf(prospect.id) < 0) {
          acc.push(prospect.id);
        }
        return acc;
      }, state.prospectIds)

    }
  },

  /** SET_COUNTER */
  [ACTIONS.SET_COUNTER]: (state, action) => {
    return {
      ...state,
      counters: {
        ...state.counters,
        [action.payload.counter]: action.payload.count,
      }
    }
  }

});

export default unitsReducer;
