/**
 * @fileoverview Defines a function to reserve a unit with the API.
 */
import axios from 'axios';
import { ROOT_URL } from './constants';


/**
 * API Endpoint
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostholdunitapi.php`;


/**
 * Reserve a unit.
 * @param {string} unitId
 */
export function reserveUnit(unitId) {
  return new Promise((resolve, reject) => {
    const params = {
      action: 'hold',
      unitno: unitId,
    }

    axios.get(API_ENDPOINT, { params }).then(response => {
      if (response.data.statustext.toLowerCase() === 'success') {
        resolve(response.data.unitno);
      } else {
        reject(response.data.error);
      }
    })
  });
}