/**
 * @fileoverview A form to input the balance booking fee details.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { Form } from 'formik';

import Field, { CleaveField } from '@Components/Field';
import ErrorLabel from '@Components/ErrorLabel';

/**
 * Prop Types
 */
const propTypes = {
  balancePayable: PropTypes.number.isRequired,
}


/**
 * <BalanceBookingFeeForm/> is a form to input the balance booking fee details.
 */
function BalanceBookingFeeForm(props) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  return (
    <Form>

      {/* Amount Due */}
      {props.balancePayable > 0 ? (
        <div className="booking-fee-amount d-flex justify-content-between">
          <span className="booking-fee-amount__title">Balance Payable</span>
          <span className="booking-fee-amount__price">IDR {props.balancePayable.toLocaleString()}</span>
        </div>
      ) : (
        <div className="booking-fee-amount d-flex justify-content-between">
          <span className="booking-fee-amount__title">Balance Payable</span>
          <span className="booking-fee-amount__price">Fully Paid</span>
        </div>
      )}

      {true &&
        <div style={{
          visibility: props.balancePayable <= 0 ? 'hidden': 'visible',
          position: props.balancePayable <= 0 ? 'absolute': 'static',
          pointerEvents: props.balancePayable <= 0 ? 'none': 'all',
        }}>
          {/* Payment Method */}
          <FormGroup>
            <Label for="method">Payment Method</Label>
            <Field name="method" type="select">
              <option value="Cash">Cash</option>
              <option value="Credit Card">Credit Card</option>
              <option value="Debit Card">Debit Card</option>
              <option value="Bank Transfer">Bank Transfer</option>
            </Field>
            <ErrorLabel name="method" {...props}/>
          </FormGroup>

          {/* Proof of Payment */}
          <FormGroup className="booking-fee-upload">
            <Label for="receipt">Upload Proof of Payment</Label>
            <Input
              className="booking-fee-upload__input"
              id="receipt"
              name="receipt"
              type="file"
              onChange={(event) => {
                if (event.currentTarget.files[0]) {
                  const objectURL = URL.createObjectURL(event.currentTarget.files[0]);
                  props.setFieldValue('receipt', objectURL)
                }
              }}
            />
            <Label for="receipt" className="booking-fee-upload__container">
              <div>
                {
                  values.receipt
                    ? <img src={values.receipt}/>
                    : <span>Select a photo</span>
                }
              </div>
            </Label>
            <ErrorLabel name="receipt" {...props}/>
          </FormGroup>
        </div>
      }
    </Form>
  )
}

BalanceBookingFeeForm.propTypes = propTypes;

export default BalanceBookingFeeForm;
