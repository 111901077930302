/**
 * @fileoverview Defines action creators to interact with the `units` store.
 */

import * as PricechartService from '@Services/PricechartService';
import * as MetaActions from '@Stores/meta/actions';

/**
 * Action Types Enum
 */
export const ACTION_TYPES = {
  GET_UNITS: 'GET_UNITS',
  SET_UNITS: 'SET_UNITS',
  REPLACE_UNIT: 'REPLACE_UNIT',
  SET_LOADING: 'SET_LOADING',
  SET_UNITS_UI: 'SET_UNITS_UI',
  STORE_PAYMENT_PLAN: 'STORE_PAYMENT_PLAN',
  SET_RESERVE_LOADING: 'SET_RESERVE_LOADING',
  SET_PAYMENT_PLAN_LOADING: 'SET_PAYMENT_PLAN_LOADING',
  SET_CLUSTER: 'SET_CLUSTER',
  SET_CLUSTER_DATA: 'SET_CLUSTER_DATA',
  GET_RESERVE_UNITS: 'GET_RESERVE_UNITS',
  SET_RESERVE_UNITS: 'SET_RESERVE_UNITS',
  SET_COUNTER: 'SET_COUNTER',
  SET_PROSPECTS: 'SET_PROSPECTS',
}


/**
 * [Thunk] Create an action that gets all units of a cluster.
 * @param {String} clusterId
 * @param {Promise<PricechartService.Unit>}
 */
export function getUnits(clusterId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading(true));

      // Create a promise to get units and also cluster data (if it's not available)
      const promise = Promise.all([
        PricechartService.getUnits(clusterId),
        getState().units.clusterData[clusterId] === undefined
          ? PricechartService.getClusterData(clusterId).catch(() => {})
          : getState().units.clusterData[clusterId],
      ]);

      promise
        .then(values => {
          const units = values[1]
            ? PricechartService.mergePosition(values[0], values[1].units)
            : values[0];
          dispatch(setUnits(units));
          dispatch(setLoading(false));
          dispatch(recalculateCounters());
          resolve(units);
        })
        .catch(error => reject(error));
    });
  };
}


/**
 * [Thunk] Create an action that gets all reserve units.
 *  * @param {String} clusterId
 */
export function getReserveUnits(clusterId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading(true));
      PricechartService.getReserveUnits(clusterId)
        .then(reserveUnits => {
          dispatch(setReserveUnits(reserveUnits));
          dispatch(setLoading(false));
          resolve(reserveUnits);
        })
        .catch(error => reject(error));
    });
  };
}


/**
 * [Thunk] Create an action to reserve a unit.
 */
export function reserveUnit(unitId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setReserveLoading(true));
      PricechartService.reserveUnit(unitId)
        .then(() => {
          const { units } = getState();
          const unit = units.units.find(u => u.unitno === unitId);
          unit.my_reserved_unit = true;
          unit.reserved_at = new Date();
          unit.status = "reserved";
          dispatch(replaceUnit(unit));
          dispatch(setReserveLoading(false));
          dispatch(recalculateCounters());
          resolve(unit);
        })
        .catch(error => reject(error));
    });
  };
}


/**
 * [Thunk] Create an action that retrieves an unit's payment plan.
 */
export function getPaymentPlan(unitId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setPaymentPlanLoading(true));
      dispatch(MetaActions.setLoading('payment_plans', true));
      PricechartService.getPaymentPlans(unitId)
        .then(plans => {
          plans = { unitno: unitId, plans: plans };
          dispatch(storePaymentPlan(plans));
          dispatch(MetaActions.setLoading('payment_plans', false));
          resolve(plans);
        })
        .catch(error => reject(error));
    });
  };
}


/**
 * [Thunk] Create an action that gets the clusters' data.
 */
export function getClusterData(clusterId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(MetaActions.setLoading('cluster_data', true));
      PricechartService.getClusterData(clusterId)
        .then(data => {
          dispatch(MetaActions.setLoading('cluster_data', false));
          dispatch(setClusterData(clusterId, data));
          resolve(data);
        })
        .catch(error => reject(error));
    });
  }
}

/**
 * [Thunk] Create an action that gets the user's prospects and stores it.
 */
export function getProspects() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(MetaActions.setLoading('prospects', true));
      PricechartService.getProspects()
        .then(prospects => {
          dispatch(MetaActions.setLoading('prospects', false));
          dispatch(setProspects(prospects));
          resolve(prospects);
        })
        .catch(error => reject(error));
    });
  }
}


export function recalculateCounters() {
  return (dispatch, getState) => {
    const { units } = getState();

    // Total
    dispatch(setCounter('all', units.units.length));

    // Available
    dispatch(setCounter('available', units.units.filter(
      u => u.my_reserved_unit || u.status === 'available'
    ).length));

    // My Reserved
    dispatch(setCounter('my_reserved', units.units.filter(
      u => u.my_reserved_unit
    ).length));

    return Promise.resolve();
  }
}


export function setClusterData(clusterId, data) {
  return {
    type: ACTION_TYPES.SET_CLUSTER_DATA,
    payload: {
      clusterId: clusterId,
      data: data,
    }
  }
}


export function setProspects(prospects) {
  return {
    type: ACTION_TYPES.SET_PROSPECTS,
    payload: prospects,
  }
}


export function setCounter(counter, count) {
  return {
    type: ACTION_TYPES.SET_COUNTER,
    payload: {
      counter: counter,
      count: count
    }
  }
}


/**
 * Stores a list of units into the store.
 */
export function setUnits(units) {
  return {
    type: ACTION_TYPES.SET_UNITS,
    payload: units
  };
}

/**
 * Stores a list of units into the store.
 */
export function setReserveUnits(units) {
  return {
    type: ACTION_TYPES.SET_RESERVE_UNITS,
    payload: units
  };
}


/**
 * Replaces a single unit in the store.
 */
export function replaceUnit(unit) {
  return {
    type: ACTION_TYPES.REPLACE_UNIT,
    payload: unit
  }
}


/**
 * Return an action to set a cluster.
 */
export function setCluster(cluster) {
  return {
    type: ACTION_TYPES.SET_CLUSTER,
    payload: cluster
  }
}

/**
 * Store a payment plan into the store.
 */
export function storePaymentPlan(plan) {
  return {
    type: ACTION_TYPES.STORE_PAYMENT_PLAN,
    payload: plan
  }
}


/**
 * Sets the unit state loading indicator.
 */
export function setLoading(value = true) {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: value,
  }
}


/**
 * Sets the reserve loading indicator.
 */
export function setReserveLoading(value = true) {
  return {
    type: ACTION_TYPES.SET_RESERVE_LOADING,
    payload: value,
  }
}


/**
 * Return an action to set the payment plan state as loading.
 */
export function setPaymentPlanLoading(value = true) {
  return {
    type: ACTION_TYPES.SET_PAYMENT_PLAN_LOADING,
    payload: value
  }
}


/**
 * Sets the active ui.
 */
export function setUi(ui) {
  return {
    type: ACTION_TYPES.SET_UNITS_UI,
    payload: ui
  }
}
