/**
 * @fileoverview Defines a function to get a list of available countries.
 */
import axios from 'axios';
import { ROOT_URL } from './constants';


/**
 * Define the API endpoint.
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostlookupcountryapi.php`;


/**
 * Get list of available countries from the API.
 */
export function getCountries() {
  return new Promise((resolve, reject) => {
    axios.get(API_ENDPOINT)
      .then(response => resolve(response.data.lookup_countries))
      .catch(error => reject(error));
  });
}
