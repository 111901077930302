/**
 * @fileoverview Defines the global Redux application store.
 */

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import unitsReducer from '@Stores/units/reducers';
import bookingsReducer from '@Stores/bookings/reducers';
import metaReducer from '@Stores/meta/reducers';


/**
 * Define the root reducer
 */
const rootReducer = combineReducers({
  units: unitsReducer,
  bookings: bookingsReducer,
  meta: metaReducer,
});


/**
 * Define the Redux application store.
 */
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(thunk),
);


export default store;
