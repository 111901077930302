/**
 * @fileoverview A function to trigger a CSS class for a specified amount of
 * time. Useful for triggering keyframe animations.
 */

const _timeoutIds = {}

/**
 * Trigger a CSS class for a specified amount of time. Useful for triggering
 * keyframe animations.
 * @param {string} selector
 * @param {string|[string]} classNames
 * @param {number} duration
 */
function triggerCssClass(selector, classNames, duration) {
  // Convert classNames to Array
  classNames = Array.isArray(classNames) ? classNames : [classNames];

  // Clear existing timeouts
  if (_timeoutIds[selector]) {
    clearTimeout(_timeoutIds[selector]);
  }

  // Apply class
  const element = document.querySelector(selector);
  if (element) {
    classNames.map(c => element.classList.add(c));

    // Remove class after specified duration
    _timeoutIds[selector] = setTimeout(() => {
      classNames.map(c => element.classList.remove(c));
      delete _timeoutIds[selector];
    }, duration);
  }
}

export default triggerCssClass;
