/**
 * @fileoverview Converts a booking to full booking.
 */
import * as yup from 'yup';
import axios from 'axios';
import { ROOT_URL } from './constants';
import qs from 'query-string';

/**
 * Define the API endpoint.
 */
const API_ENDPOINT = `${ROOT_URL}/administration/API/webpostconverttofullbookapi.php/`


/**
 * Define the API schema.
 */
const apiSchema = yup.object().shape({
  ref_id: yup
    .string()
    .required(),
  unit_id: yup
    .string()
    .required(),
  payment_reference2: yup
    .string()
    .required(),
  booking_fee_full: yup
    .number()
    .required(),
  file_pop_full: yup
    .string()
    .required()
});


/**
 * Converts a booking to full booking with the API.
 */
export function convertToFullBook(data) {
  return new Promise((resolve, reject) => {
    const params = {
      'ref_id': data.bookingId,
      'unit_id': data.unitId,
      'payment_reference2': data.paymentReference,
      'booking_fee_full': data.balancePaid,
      'file_pop_full': data.proofOfPayment,
    }

    // Validate data
    try {
      apiSchema.validateSync(params)
    } catch (errors) {
      reject(errors);
      return;
    }

    // Convert the booking to full book
    axios.post(API_ENDPOINT, qs.stringify(params)).then(response => {
      if (response.data.statustext.toLowerCase() === "success") {
        resolve(true)
      } else {
        reject(response.data.error);
      }
    });
  });
}