/**
 * @fileoverview A dialog to request for an email address to submit comparison.
 */
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';

import Button from '@Components/Button';
import SVGIcon from '@Components/SVGIcon';

/**
 * `<EmailRequestDialog/>` is a dialog to request for an email address to submit
 * the unit comparison details.
 */
class EmailRequestDialog extends Component {

  /**
   * Prop Types
   */
  static propTypes = {
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
  }

  /**
   * Default Props
   */
  static defaultProps = {
    isOpen: false,
    isLoading: false,
  }

  /**
   * Internal State
   */
  state = {
    email: '',
    error: '',
  }

  /**
   * Toggles self, but only if nothing is loading.
   */
  toggle = () => {
    if (this.props.isLoading) {
      return;
    }
    this.props.onToggle();
  }

  /**
   * Handles when the email input value changes.
   */
  handleEmailInputChange = (e) => {
    this.setState({ email: e.target.value, error: '' });
  }

  /**
   * Handles submit button clicks.
   */
  handleSubmit = () => {
    if (_.isEmpty(this.state.email)) {
      this.setState({ error: 'This field is required.' });
      return;
    }

    const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(this.state.email)) {
      this.setState({ error: 'Enter a valid email address.' });
      return;
    }

    this.props.onSubmit(this.state.email);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.toggle}
        centered
      >
        <ModalHeader>
          Enter Email Address
        </ModalHeader>
        <ModalBody>
          <p>
            A summary of this comparison chart will be emailed to the
            following email address.
          </p>
          <FormGroup>
            <Input
              id="email"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleEmailInputChange}
            />
            <span className="form-error">{this.state.error}</span>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            width="sm"
            disabled={this.props.isLoading}
            onClick={this.toggle}
          >
            Cancel
          </Button>
          <Button
            color="cyan"
            width="sm"
            disabled={this.props.isLoading}
            loading={this.props.isLoading}
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

}

export default EmailRequestDialog;
